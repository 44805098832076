
.offering_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.offering_box{
    margin-left: -10px;
    margin-right: -15px;
    overflow: scroll;
    height: calc(100% - 75px);
}
.active_offering_box{
    overflow-x: hidden;
    padding: 15px 30px;
    overflow-y: auto;
    height: 100%;
}
.offering-unread-indication {
    position: absolute;
    top: 1rem;
    background-color: green;
    right: 2rem;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    border: 1px solid #ddd;
    z-index: 99;
}
.offering-read-indication {
    position: absolute;
    top: 1rem;
    background-color: #ddd;
    right: 2rem;
    width: 16px;
    height: 16px;
    border-radius: 100px;
    border: 1px solid gray;
    z-index: 99;
}
.active_offering_box::-webkit-scrollbar {
    width: 6px;
}
.active_offer{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    border: 2px solid lightgray;
    padding-bottom: 3px;
    margin-top: 5px;
}
.offering_box_inner_content{
    border: 1px solid lightgray;
    padding: 15px 0;
}
.dsp-indication {
    position: relative;
}
.dsp-indication .dsp-indication-image {
    position: absolute;
    width: 100px;
    top: 0px;
    left: 15px;
}
.dsp-indication .dsp-indication-image img{
    width: 100%;
}
.employe_images{
    width: 100px;
    object-fit: contain;
    height: 100px;
}
.button-groups {
    .MuiButton-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
    }
}
.offering-name-box {
    display: flex;
    justify-content: center;
    height: 55px;
    align-items: center;
    margin-top: 10px;
}
.offering-name-box p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.company_name{
    font-weight: bold;
    font-size: 18px;
    color: #245776;
    text-align: center;
}
.company_name_read{
    font-size: 18px;
    color: #245776;
    text-align: center;
}
.company_tag{
    font-weight: bold;
    font-size: 15px;
    color: #a7a7a7;
    margin-bottom: 10px;
    text-align: center;
}
.company_tag_read{
    font-weight: 400;
    font-size: 15px;
    color: #a7a7a7;
    margin-bottom: 10px;
    text-align: center;
}
.company_account{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.account_type{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.account_open_date{
    float: right;
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 14px;
}
.price_level_type{
    margin-bottom: 7px !important;
    font-weight: 500;
    font-size: 15px;
}
.amount{
    float: right;
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.offering_list a:hover{
    text-decoration: none
}
.follow_button{
    text-align: right;
}
.follow_button button{
    width: 100%;
    max-width: 150px;
    background: transparent;
    border: 2px solid rgba(160, 219, 65, 1) !important;
    color: rgba(160, 219, 65, 1);
    font-size: 17px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    padding: 10px;
}
.order_now_button{
    text-align: center;
    margin-bottom: 15px;
}
.order_now_button a{
    background-color: #4f4f4f;
}
.order_now_button button{
    color: rgba(245,245,245,1);
    background: #4f4f4f;
}
.order_now_interested_button{
    text-align: center;
    text-align: right;
}
.order_now_interested_button button:not([class]){
    background: transparent !important;
    border: 2px solid #44C6D2 !important;
    padding: 8px 10px !important;
    box-shadow: none !important;
    color: #44C6D2;
}
.order_now_button a,
.order_now_interested_button button {
    width: 100%;
    max-width: 100%;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border: none !important;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}
.order_now_button a {
    display: block;
}
.follow_button button:focus{
    outline: none
}
.filter_by{
    margin-bottom: 0px !important;
}
.filter_by select{
    width: 70%;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    color: #495057;
    border-radius: 5px;
    padding: 8px;
    outline: none;
    margin-left: 5px;
}
.filter_text{
    margin-top: 19px;
    margin-bottom: 0px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #85d541;
}
@media screen and (max-width: 1199px) {
    .amount {
        font-size: 14px;
    }
}
@media (max-width: 767px){
    .filter_by select{
        width: 100%;
        margin-left: 0px;
    }
    .offering_content{
        margin-top: 0px;
    }
    .account_open_date{
        margin-left: -5px;
        font-size: 13px;
    }
    .add-on{
        width: 100% !important;
    }
    .active_offer{
        font-weight: bold;
    }
    .follow_button {
        text-align: center;
    }
    .follow_button button{
        width: 100%;
        margin-left: 0px;
        font-size: 15px;
        margin-top: 15px;
    }
    .active_offering_box{
        overflow-x: hidden;
        margin-bottom: 20px;
    }
    .employe_images {
        width: 42px !important;
        margin-left: 6px;
    }
    .company_name{
        font-size: 15px;
    }
    .company_tag{
        font-size: 13px;
    }
    .order_now_button button{
        width: 100%;
        margin-left: 0px;
        font-size: 14px;
    }
    .order_now_interested_button button{
        width: 100%;
        margin-left: 0px;
        font-size: 15px;
    }
}
body .offering_content .form-group .p-multiselect .p-multiselect-label{
    white-space: nowrap;
}
.add-on {
    border: 1px solid lightgrey;
    border-radius: 0;
    width: 80% !important;
    float: right;
}
.search-field {
    position: relative;
    border: 1px solid lightgrey;
    border-radius: 0;
    width: 80% !important;
    float: right;
}
.search-field:after {
    content: '\f002';
    font-family: FontAwesome;
    color: #999999;
    text-align: left;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}
.offering_filters_row .search-field,
.offering_filters_row .search-field .form-control  {
    border-radius: 4px !important;
}

.add-on .form-control {
    border: 1px solid #ccc !important;
    border-radius: 0px !important;
    padding: 0 0 0 10px !important;
    border: 1px solid lightgrey;
    border-radius: 0;
    width: 80% !important;
    float: right;
}
.search-field .form-control {
    border: none !important;
    border-radius: 0px !important;
    padding: 0 0 0 35px !important;
    border: 1px solid lightgrey;
    border-radius: 0;
    width: 100% !important;
    float: right;
    background: transparent !important;
}
.turquoise_interested_btn{
    background: #44C6D2 !important;
    color: #fff !important;
    border-color: #44C6D2 !important; 
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}
/* New Design CSS */
.mb-5px {
    margin-bottom: 5px;
}
.mb-15px {
    margin-bottom: 15px;
}
.ml-mr-0px-imp {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.page_title {
    color: #1c1c1c !important;
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
    position: relative;
}
.page_title:after {
    content: '';
    height: 1px;
    width: 100%;
    max-width: 135px;
    margin: 0 auto;
    display: block;
    background-color: #1c1c1c;
}
.multi-select .options{
    height: auto !important;
}
.mt-30px {
    margin-top: 30px;
}
.pt-30px {
    padding-top: 30px;
}
.pl-pr-15px {
    padding-left: 15px;
    padding-right: 15px;
}
@media screen and (max-width: 767px) {
    .search-field {
        width: 100% !important;
    }
    .offering_filters_row .form-group {
        margin-bottom: 1rem !important;
    }
    .active_offering_box > div.row:first-child {
        height: calc(100vh - 270px);
        overflow-y: auto;
    }
}
@media screen and (max-width: 575px) {
    .active_offering_box > div.row:first-child {
        height: calc(100vh - 325px);
        overflow-y: auto;
    }
    .offering_box_inner_content > .row > div:last-child {
        text-align: center;
    }
    .offering_box_inner_content > .row > div:last-child > div {
        width: calc((100% - 15px) / 2);
        display: inline-block;
        vertical-align: top;
    }
    .offering_box_inner_content > .row > div:last-child > div:only-of-type {
        width: 100%;
    }
    .offering_box_inner_content > .row > div:last-child > div:not(:first-child) {
        margin-left: 15px;
    }
    .order_now_button button,
    .order_now_interested_button button {
        max-width: 100%; 
    }
    .active_offering_box {
        padding: 15px;
    }
    .offering_box_inner_content {
        margin-bottom: 25px;
    }
}
@media (max-width: 375px) {
    .page_title {
    font-size: 16px !important;
    margin-top: 20px !important;
    padding-bottom: 0 !important;
    }
    .back_arrow_icon {
    max-width: 20px;
    margin-top: -5px;
    }
}
.active_offering_box > .row > div:nth-child(3n + 1) > div > div {
    margin: 0 15px;
}
.offering_box_inner_content .order_now_button,
.offering_box_inner_content .order_now_button + .order_now_interested_button  {
    width: 48%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
}
.offering_box_inner_content .order_now_button + .order_now_interested_button {
    margin-left: 4%;
}
.offering_box_inner_content > div > div:nth-child(3) > div:only-of-type {
    text-align: center;
}

.offering_box_inner_content {
    margin-bottom: 30px;
}

.active_offering_box {
    padding: 15px 25px 15px 30px;
}

@media screen and (min-width: 576px) and (max-width: 1199px) {
    .active_offering_box > .row > div:nth-child(odd) {
        padding-left: 0;
        padding-right: 7.5px;
    }
    .active_offering_box > .row > div:nth-child(even) {
        padding-right: 0;
        padding-left: 7.5px;
    }
    .my-masonry-grid {
        width: auto !important;
    }
}
@media screen and (min-width: 576px) {
    .offering_box_inner_content > .row {
        -ms-box-orient: horizontal;
        min-height: 350px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-align-content: space-between;
        align-content: space-between;
    }
    .comment-modal-style {
        min-width: 800px;
        padding: 0;
    }
    .onboarding-modal-style {
        min-width: 600px;
        padding: 0;
        border-radius: 25px;
    }
}
@media screen and (max-width: 575px) {
    .active_offering_box > .row > div {
        padding-left: 0;
        padding-right: 0;
    }
    .my-masonry-grid {
        width: auto !important;
        margin-left: -15px;
        margin-right: -15px;
    }
    .comment-modal-style {
        min-width: 300px;
        padding: 0;
    }
    .onboarding-modal-style {
        min-width: 300px;
        padding: 0;
    }
}
.my-masonry-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.my-masonry-grid_column {
    background-clip: padding-box;
}
.LazyLoad {
    width: 100px;
    margin: 0 auto;
    height: 100px;
}
.LazyLoad .employe_images {
    height: 100px !important;
    object-fit: contain !important;
    width: 100px !important;
    object-position: center !important;
}
.goOfferingDetails{
    cursor: pointer;
}
.order_now_interested_button.not_ipo_interested_btn button {
    border-color: #85d541 !important;
    color: #85d541;
}

.order_now_interested_button_two button {
    border: 2px solid transparent !important;
    padding: 8px 10px !important;
    color: #fff;
}

.order_now_interested_button_two.not_ipo_btn button {
    background-color: #44c6d2 !important;
}
.order_now_interested_button_two button {
    background-color: #85d541 !important;
    color: #ffffff !important;
}

.account_open_date_two {
    font-weight: normal !important;
}

.offering_content .offering_filters_row .p-multiselect.p-component {
    width: calc(100% - 1px) !important;
}
.dv-star-rating i{
    font-size: 16px;
}
.dv-star-rating label{
    margin-bottom: 0rem;
    line-height: 22px;
}
.see-all-comments {
    cursor: pointer;
    /* color: #5fcc40; */
}
.comment-loading {
    text-align: center;
    margin-top: 30px;
}
.comment-loading img{
    width: 35px;
}
.comment-modal-box {
    height: 100%;
    position: relative;
}
.comment-modal-box-header {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    background: #fff;
    z-index: 9;
}
.comment-modal-box-header .comment-title {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
}
.comment-modal-box-content {
    min-height: 400px;
    overflow-y: auto;
    padding-top: 60px;
    padding-bottom: 200px;
    padding-left: 10px;
    padding-right: 10px;
    max-height: 600px;
}
.comment-modal-box-content .comment-list {
    padding: 10px;
    border-top: 1px solid #ddd;
    position: relative;
}
.comment-modal-box-content .edit-delete-btn {
    position: absolute;
    right: 20px;
    top: 15px;
}
.edit-btn-comment {
    background: #245776 !important;
    border-color: #245776 !important;
    border: none !important;
    margin: 0 5px;
    color: #fff !important;
    /* padding: 5px; */
    cursor: pointer;
    /* width: 30px;
    height: 30px; */
    border-radius: 4px;
}
.delete-btn-comment {
    background: red !important;
    border-color: red !important;
    margin: 0 5px;
    color: #fff !important;
    border: none !important;
    /* padding: 5px; */
    cursor: pointer;
    /* width: 30px;
    height: 30px; */
    border-radius: 4px;
}
.comment-modal-box-content .comment-list-alies {
    font-size: 18px;
}
.comment-modal-box-content .comment-list-rating .dv-star-rating i{
    font-size: 22px;
}
.comment-modal-box-content .comment-list-rating .dv-star-rating label{
    margin-bottom: 0rem;
    line-height: 22px;
}
.comment-modal-box-content .comment-list-rating {
    position: relative;
}
.comment-modal-box-content .comment-list-rating .updated-date {
    margin-left: 20px;
    font-size: 12px;
    position: absolute;
    top: 3px;
    color: #999;
}
.comment-modal-box-content .comment-list-comment {
    /* text-align: center; */
    word-break: break-all;
}

.comment-modal-box-footer {
    position: absolute;   
    bottom: 0;
    width: 100%; 
    padding: 15px;
    border-top: 1px solid #ddd;
    background: #fff;
    z-index: 9;
}
.comment-modal-box-footer .comment-footer-rating {
    text-align: center;
}
.comment-modal-box-footer .comment-footer-rating .comment-footer-rating-title {
    margin-bottom: 10px;
    color: #5fcc40;
}
.comment-modal-box-footer .comment-footer-rating .dv-star-rating i{
    font-size: 48px;
}
.comment-modal-box-footer .comment-footer-rating .dv-star-rating label{
    margin-bottom: 0rem;
    line-height: 22px;
}
.comment-modal-box-footer .comment-submit-btn {
    text-align: center;
}
.comment-modal-box-footer .comment-submit-btn button{
    background: #5fcc40;
    color: #fff;
    margin: auto;
    cursor: pointer;
    padding: .25rem 1rem !important;
    font-size: 24px !important;
}

.comment-modal-style .styles_closeButton__20ID4{
    z-index: 99;
}
.color-of-star-half {
    color: rgb(255, 180, 0);
}
.onboarding-modal-style .styles_closeButton__20ID4{
    z-index: 99;
}
.onboarding-modal-box {
    height: 100%;
    position: relative;
}
.onboarding-modal-style .onboarding-modal-box{
    padding: 2rem 6rem;
}
.onboarding-modal-style .styles_closeButton__20ID4 {
    display: none;
}
.onboarding-modal-style .onboarding-modal-box .continuebtn{
    /* padding: 10px; */
    width: 70%;
    /* max-width: 150px; */
    font-size: 16px;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
    border: none !important;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
    background-image: linear-gradient(to right,rgba(160, 219, 65, 1) 0%,rgba(109, 206, 64, 1) 100%);
    color: #fff;
    margin-top: 0 !important;
    margin-bottom: 15px;
}
.onboarding-modal-style .onboarding-modal-box .continuebtn-box{
    text-align: center;
    margin-top: 50px;
}
.onboarding-modal-style .onboarding-modal-box  .slider-box .slider-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #245776;
    margin-bottom: 30px;
}
.onboarding-modal-style .onboarding-modal-box  .slider-box .slider-caption {
    text-align: center;
    font-size: 14px;
    color: #245776;
    margin-bottom: 30px;
}
.onboarding-modal-style .onboarding-modal-box .investor-icon {
    width: 140px;
    margin: auto;
}
.onboarding-modal-style .onboarding-modal-box .investor-icon img {
    width: 100%;
    margin-bottom: 30px;
}
.onboarding-modal-style .onboarding-modal-box  .slider-box .slider-caption-vailabilityone {
    font-size: 14px;
    color: #245776;
}
.onboarding-modal-style .onboarding-modal-box  .slider-box .slider-caption-vailability {
    font-size: 14px;
    color: #245776;
    margin-bottom: 30px;
}
.onboarding-modal-style .onboarding-modal-box  .slider-box .slider-caption span {
    text-align: center;
    font-size: 14px;
    color: #245776;
    margin-bottom: 30px;
}