.faq_content{
    margin: auto;
    height: 100%;
}
.faq_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}

.faq-section{
    margin: 10px 0;
    position: relative;
}

/*Hide the paragraphs*/
.faq-section p{
    display: none;
}   

/*Hide the checkboxes */
.faq-section input{
    position: absolute;
    z-index: 2;
    cursor: pointer;
    opacity: 0;         
    display: none\9; /* IE8 and below */
    margin: 0;          
    width: 100%;
    height: 36px;
}

/*Show only the clipped intro */
.faq-section label+p{
    display: block; 
    color: #999;
    font-size: .85em;
    transition: all .15s ease-out;
    /* Clipping text */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 5px;
}

/*If the checkbox is checked, show all paragraphs*/
.faq-section input[type=checkbox]:checked~p{
    display: block;
    color: #444;
    font-size: 1em;
    /* restore clipping defaults */
    text-overflow: clip; 
    white-space: normal;
    overflow: visible;
    text-align: justify;
    padding-left: 8px;
    padding-right: 8px;
}

/*Style the label*/
.faq-section label {
    font-size: 15px;
    background: #eee;
    display: block;
    position: relative;
    padding: 8px 10px;
    font-weight: bold;
    border: 1px solid #ddd;
    border-left: 3px solid #888;
    text-shadow: 0 1px 0 rgba(255,255,255,.5);
    transition: all .15s ease-out;
}
/*Remove text selection when toggle-ing*/
.faq-section label::selection{
    background: none;
}

.faq-section label:hover{
    background: #f5f5f5;
}

/*If the checkbox is checked, style the label accordingly*/
.faq-section input[type=checkbox]:checked~label{
    border-color: #8bc53fbd;
    background: #8bc53f9c;
    font-weight: 500;
    box-shadow: 0 0 1px rgba(0,0,0,.4);             
}

/*Label's arrow - default state */
.faq-section label::before{
    content: '';
    position: absolute;
    right: 4px;
    top: 58%;
    margin-top: -10px;
    border: 6px solid transparent;
    border-left-color: inherit; 
}

/*Update the right arrow*/
.faq-section input[type=checkbox]:checked~label::before{
    border: 6px solid transparent;
    border-top-color: #212543;
    margin-top: -5px;
    right: 10px;    
}
.faq_inner_content{
    background-color: #fff;
    overflow-y: auto;
    height: calc(100% - 0px);
    padding: 15px;
    border-radius: 4px;
}
@media (max-width: 767px){
    .faq_content{
        margin-top: 0px;
        padding-bottom: 10px;
    }
    .faq-section label{
        text-overflow: ellipsis;
        white-space: pre-wrap;
        overflow: hidden;
        font-size: 14px;
        padding-right: 25px;
        height: max-content;
    }
    .faq-section label::before{
        top: 58%;
    }
    .faq-section input[type=checkbox]:checked~p{
        font-size: 14px;
    }
    .faq-section input{
        position: absolute;    
        width: 100%;
        height: 100%;
    }
}