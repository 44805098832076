
.offring_deatils_content{
    margin: auto;
    height: 100%;
    background: #fff;
    padding-top: 20px;
}
.offring_deatils_heading{
    text-align: center;
    height: 50px;
    font-size: 27px;
    color: #083952 !important;
    margin-bottom: 15px;
    position: relative;
}
/* .offring_deatils_heading  */
.back_arrow_icon {
    width: 100%;
    max-width: 25px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}
.back_icon {
    width: 100%;
    max-width: 35px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}
.offring_deatils_inner_content{
    margin-top: 15px;
    margin: auto;
    background: #fff;
    padding: 15px 0;
    height: calc(100% - 68px);
    overflow-y: auto;
}
.prospectus_link_url{
    text-decoration: underline;
    color: blue !important;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
}
.details_logo{
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
    max-height: 140px;
    object-fit: contain;
}
.company_offringdeatils_tag{
    font-weight: bold !important;
    font-size: 27px !important;
    margin-bottom: 0px !important;
    color: #678899 !important;
    text-align: center;
}
.company_offringdeatils_account{
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    font-size: 17px;
}
.price_level_offering_type {
    margin-bottom: 7px !important;
    font-weight: 500 !important;
    /* font-size: 15px; */
    color: #678899 !important;
}
.company_offringdeatils_name{
    margin-bottom: 5px !important;
    font-weight: 600 !important;
    font-size: 17px;
    color: #245776 !important;
}
.account_offringdeatils_type{
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    font-size: 17px;
    color: #678899 !important
}
.account_open_offringdeatils_date{
    float: right;
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    font-size: 17px;
    color: #336e8d !important;
}
.price_level_offringdeatils_type{
    margin-bottom: 3px !important;
    font-weight: 500;
    font-size: 17px;
}
.amount_offringdeatils{
    float: right;
    margin-bottom: 5px !important;
    font-weight: 500;
    font-size: 17px;
}
.order_status{
    font-weight: 500 !important;
    font-size: 16px;
    color: #336e8d !important;
    margin-bottom: 5px;
    display: block;
}
.order_status_text{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: justify;
    word-break: break-all;
    color: #678899;
}
.industries_content{
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    color: #678899 !important;
    font-size: 16px;
}
.industries_content_text{
    float: right;
    /* font-size: 16px; */
    font-weight: 600 !important;
    margin-bottom: 5px;
}
.item_link{
    margin-bottom: 5px !important;
    font-weight: 500;
    font-size: 16px;
    color: #336e8d;
}
.link{
    float: right;
    font-size: 16px;
    margin-bottom: 0px;
    color: blue !important ;
    text-decoration: underline;
    cursor: pointer;
    word-break: break-all;  
}
.amount_offring{
    float: right;
    margin-bottom: 0px !important;
    font-weight: 500 !important;
    /* font-size: 15px; */
    color: #336e8d !important;
}
.share_status{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.share_amount{
    float: right;
    /* font-weight: bold; */
    font-size: 16px;
}
.details_btn{
    margin: auto;
    text-align: center;
}
.details_btn a {
    display: block;
}
.details_btn .btn{
    width: 100%;
    max-width: 150px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border: none !important;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
    background-image: linear-gradient(to right,rgba(160, 219, 65, 1) 0%,rgba(109, 206, 64, 1) 100%);
    color: #fff;
    margin-top: 0 !important;
    margin-bottom: 15px;
}
.details_btn a{
    color: #fff !important;
    text-decoration: none !important;
}
.media_facebook_image img{
    width: 42px;
    cursor: pointer;
}
.media_twitter_image img{
    width: 42px;
    cursor: pointer;
}
.media_stocktwits_image img{
    width: 42px;
    cursor: pointer;
}
.media_linkedin_image img{
    width: 42px;
    cursor: pointer;
}
.description_heading{
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 3px !important;
    color: #336E8D;
}
.description_content{
    margin-right: 0px;
    margin-bottom: 5px;
    /* font-size: 15px; */
    text-align: left;
    color: #678899;
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
        hyphens: auto;

    max-height: 85px;
    overflow: hidden;
    overflow-y: auto;
    text-align: justify;
    padding-right: 5px;
}
.offering-prospectus-row {
    display: flex;
}
.offering-prospectus-col-pros {
    margin-right: 35px;
}
.share_text{
    line-height: 2.1;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
    color: #336E8D !important;
    text-transform: uppercase;
    margin-right: 8px;
    margin-bottom: 5px;
}
.follow_offering_details_button{
    text-align: center;
}
.follow_offering_details_button button{
    min-width: 150px;
    background: transparent;
    border: 2px solid rgba(160, 219, 65, 1);
    color: rgba(160, 219, 65, 1);
    font-size: 17px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    padding: 8px 10px;
    margin-bottom: 15px;
}
.follow_offering_details_button button .ipo_interested_btn {
    border-color: #5fcc40 !important;
}
.follow_offering_details_button button:not([class]) {
    padding: 8px 10px !important;
}
.follow_offering_details_button button.interested_btn {
    border: none !important;
    padding: 12px;
}
.follow_offering_details_button button:focus{
    outline: none
}
.rt-input-input {
    position: relative;
    display: inline-block;
}
.rt-input-input::after {
    content: '$';
    font-family: Open Sans,sans-serif !important;
    width: 1em;
    height: 1em;
    position: absolute;
    top: 44%;
    left: 5px;
    transform: translateY(-50%);
}
.reconfirm__text{
    color: rgba(74,74,74,1);
    text-align: center;
}
.confirm_order_button{
    width: 100%;
}
.iframe_offeringdetails{
    border: none;
    border-top: 1px solid #ccc;
    width: 100%;
    margin-bottom: -22PX;
    height: calc(100% - 227px);
    min-height: 200px;
    word-break: break-all;
}

@media (max-width: 767px){
    .offring_deatils_content{
        margin-top: 0px;
    }
    .offring_deatils_inner_content{
        width: 100%;
        margin: auto;
    }
    .account_offringdeatils_type{
        font-size: 16px
    }
    .account_open_offringdeatils_date{
        font-size: 16px;
    }
    .amount_offring{
        margin-right: 0px;
    }
    .link{
        font-size: 14px;
    }
    .item_link{
        font-size: 16px;
        color: #336e8d;
    }
    .company_offringdeatils_tag{
        font-size: 13px;
        margin: 15px 0 !important;
    }
    .details_logo{
        width: 100%
    }
    .description_heading{
        font-size: 16px;
    }
    .description_content{
        font-size: 14px;
    }
    .share_amount{
        margin-right: 14px;
    }
    .share_text{
        line-height: 1;
    }
    .offring_deatils_inner_content_inner p.share_text {
        margin-bottom: 10px !important;
    }
    .media_facebook_image img,
    .media_twitter_image img,
    .media_stocktwits_image img,
    .media_linkedin_image img {
        width: 30px;
    }
    .order_status_text{
        font-size: 15px;
    }
}

@media screen and (max-width: 575px) {
    .company_offringdeatils_tag {
        text-align: left;
    }
}
@media screen and (max-width: 375px) {
    .media_facebook_image img,
    .media_twitter_image img,
    .media_stocktwits_image img,
    .media_linkedin_image img {
        width: 25px;
    }
    .media_facebook_image,
    .media_twitter_image {
        margin-right: 9px !important;
        margin-bottom: 2px;
    }
}
.cancel_btn{
    width: 100%;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border: none !important;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
    color: #fff;
    background: red;
}
.offring_deatils_inner_content_middle p,
.offring_deatils_inner_content_middle span {
    color: #1c1c1c;
    font-weight: normal;
}
.offring_deatils_inner_content_middle .investment_content .link {
    color: #3a6ce5 !important;
}
.media_facebook_image,
.media_twitter_image,
.media_stocktwits_image,
.media_linkedin_image {
    display: inline-block;
    vertical-align: middle;
}
.media_facebook_image {
    margin-right: 10px;
}
.media_twitter_image{
    margin-right: 10px;
}
.media_facebook_image img,
.media_twitter_image img,
.media_stocktwits_image img,
.media_linkedin_image img {
    max-width: 30px;
}
.company_offringdeatils_tag {
    margin-bottom: 10px;
}
.offring_deatils_inner_content_inner {
    margin-bottom: 10px;
}
.offring_deatils_inner_content_inner p {
    color: #333333;
    margin-bottom: 0 !important;
    font-weight: 600;
}
.offering-detail-buttons-wrapper {
    margin-top: 25px;
}

@media screen and (max-width: 479px) {
    .follow_offering_details_button button,
    .details_btn .btn {
        padding-left: 5px;
        padding-right: 5px;
        max-width: 100%;
        font-size: 14px;
    }
    .offering-detail-buttons-wrapper > .has_connect_brokrage_account_btn,
    .offering-detail-buttons-wrapper > .has_connect_brokrage_account_btn + .details_btn {
        width: 100% !important;
    }
    .offering-detail-buttons-wrapper > .has_connect_brokrage_account_btn + .details_btn {
        margin-left: 0 !important;
    }
    .offering-detail-buttons-wrapper > div:nth-child(3) {
        width: 100%;
    }
    .offring_deatils_inner_content_inner p {
        font-size: 14px;
    }
    .offring_deatils_inner_content > .row:first-child > div:nth-child(1),
    .offring_deatils_inner_content > .row:first-child > div:nth-child(2) {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.mb-15px-imp {
    margin-bottom: 15px !important;
}
.offering_details_intrested_btn button {
    padding: 10px !important;
    font-size: 16px
}
.share_social_icons {
    display: inline-block;
    vertical-align: middle;
}
.share_social_icons > a {
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    font-size: 35px !important;
}
.share_social_icons > a:nth-child(1) {
    color: #37579c !important;
    margin-right: 8px;
}
.share_social_icons > a:nth-child(2) {
    color: #2aaae0 !important;
    margin-right: 10px;
}
.media_twitter_image img {
    background: #2aaae0;
    border-radius: 4px;
}
.media_facebook_image img{
    background: #2f5597;
    border-radius: 4px;
}
.share_social_icons + .media_stocktwits_image img {
    background: #40576f;
    border-radius: 5px;
    margin-right: 9px;
}
.media_stocktwits_image + .media_linkedin_image img {
    background: #007ab9;
    border-radius: 5px;
    margin-right: 8px;
}
@media screen and (max-width: 375px) {
    .share_social_icons > a {
        font-size: 30px !important;
    }
    .share_social_icons > a:nth-child(1) {
        margin-right: 4px;
    }
    .share_social_icons > a:nth-child(2) {
        margin-right: 6px;
    }
}
.icon_text{
    color: #44C6D2;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    margin-bottom: 20px;
}
.prospectus_modal_text{
    margin-top: 5px;
    font-weight: 500;
    color: #44C6D2;
    margin-bottom: 0;
    font-size: 18px;
    cursor: pointer;
    display: inline-block;
}
.title_content{
    margin-top: 20px;
    font-weight: 500;
    font-size: 17px;
}
.go_back{
    color: #c4c4c4;
    position: absolute;
    left: 31px;
    top: 50%;
    font-size: 14px;
    transform: translateY(-50%);
    cursor: pointer;
}
.offering_name{
    color: #083952;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 5px;
}

@media (max-width: 575px) {
    .back_icon,
    .go_back {
        position: static;
        transform: initial;
        display: inline-block;
        vertical-align: middle;
    }
    .back_icon_text {
        text-align: left;
        margin-top: 10px;
    }
    .offring_deatils_heading {
        line-height: 1;
    }
    .offering_detail_title {
        display: block;
        margin-bottom: 15px;
    }
    .offring_deatils_heading {
        height: auto;
    }
    .offring_deatils_inner_content {
        height: calc(100% - 88px);
    }
}
.secondry_btn{
    width: 100%;
}

.not_ipo_interested_btn button {
    border-color: #5fcc40 !important;
    color: #5fcc40 !important;
}
.not_follow_interested_btn button {
    border-color: #44c6d2 !important;
    color: #44c6d2 !important;
}
.not_etf_interested_btn button {
    border-color: #009167 !important;
    color: #009167 !important;
}
.not_uit_interested_btn button {
    border-color: #0493AD !important;
    color: #0493AD !important;
}
.follow_offering_details_button .turquoise_interested_btn {
    border-color: #44c6d2 !important;
}
.follow_offering_details_button .ipo_interested_btn {
    border-color: #5fcc40 !important;
}

.social_modal--header {
    display: flex;
    align-items: center;
    padding-bottom: 1.2rem;
}
.social_modal--header__left {
    width: 100px;
}
.social_modal--header__left img {
    width: 100%;
    margin: 0;
}
.social_modal--header__right {
    padding-left: 1.2rem;
}
.social_modal--header__right p:first-child {
    margin-bottom: 0;
    font-size: 30px;
}
.social_modal--body {
    margin: 0 -1.2rem; 
}
.social_modal--body__top {
    padding: 1.2rem;
    background-color: #f1f1f1;
    text-align: center;
}
.social_modal--body__bottom {
    padding: 1.2rem;
}
.social_modal--footer {
    margin: 0 -1.2rem -1.2rem;
}
.social_modal--footer button {
    border: none;
    padding: 10px;
    color: #fff;
    width: 100%;
    display: block;
    text-align: center;
    cursor: pointer;
}
.social_modal--footer button img {
    width: 25px;
    margin-right: 10px;
}
.social_modal--footer__button_facebook {
    background: #2f5597;
}
.social_modal--footer__button_twitter {
    background: #2aaae0;
}
.social_modal--footer__button_stocktwits{
    background: #40576f;
}
.social_modal--footer__button_linked {
    background: #007ab9;
}
.social_modal--footer__img_facebook {
    transform: translateY(-2px);
}
.social_modal--footer__img_twitter {
    transform: translateY(-3px);
}
.social_modal--footer__img_stocktwits {
    transform: translateY(-2px);
}
.social_modal--footer__img_linkedin {
    transform: translateY(-3px);
}



.biz_insights_box{
    /* text-align: center; */
    margin-top: 20px;
    margin-bottom: 30px;
}
.biz_insights_btn{
    background: transparent;
    border: 2px solid #678899;
    padding: 8px 20px;
    font-size: 20px;
    border-radius: 100px;
    color: #678899;
}
.biz-insights-box-content-first{
    background: #002748;
    color: #fff;
    margin-top: 50px;
}
.biz-insights-box-content-first-logo{
    margin-bottom: 5px;
    margin-top: 5px;
    padding-bottom: 5px;
    padding-top: 5px;

}
.biz-insights-box-content-first-logo img{
    width: 100%;
    /* margin-top: 6px; */
}
.biz-insights-box-content-first-content p{
    margin-bottom: 5px;
    margin-top: 5px;
    color: #ffffff;
    font-size: 14px;
}
.biz-insights-box-content-first-content {
    padding-bottom: 5px;
    padding-top: 5px;
    margin-top: 8px;
}
.biz-insights-box-content-second{
    /* text-align: center; */
    padding: 30px;
}
.biz-insights-box-content-second-logo{
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;

}
.biz-insights-box-content-second-name{
    font-size: 24px;
    color: #555;
    text-align: center;

}
.second-biz-content-box{
    margin-top: 50px;
}
.second-biz-content-left{
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    color: #678899 !important;
    font-size: 16px;
}
.second-biz-content-right{
    text-align: right;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    color: #678899 !important;
    font-size: 16px;
}
.biz-insights-box-content-third{
    background: #0a5074;
    color: #fff;
    padding: 30px !important;
}
.biz-insights-box-content-third .company-title{
    margin-top: 10px;
    margin-bottom: 10px;
}
.biz-insights-box-content-third-left{
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    color: #678899 !important;
    font-size: 16px;
}
.biz-insights-box-content-third-right{
    margin-bottom: 5px !important;
    font-weight: 500 !important;
    color: #678899 !important;
    font-size: 16px;
}
.biz-insights-box-content-fourth{
    background: #fff;
    padding: 30px !important;
}
.biz-insights-box-content-fourth .business-title{
    text-transform: uppercase;
    font-size: 18px;
    color: #0a5074;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}
.biz-insights-box-content-fourth .business-description{
    font-size: 18px;
    color: #678899;
    font-weight: 500;
    margin-bottom: 20px;
}
.biz-insights-box-content-fifth{
    background: #f5f5f5;
    padding: 30px !important;
}
.biz-insights-box-content-fifth .market-title{
    text-transform: uppercase;
    font-size: 18px;
    color: #0a5074;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}
.biz-insights-box-content-fifth .market-description{
    font-size: 18px;
    color: #678899;
    font-weight: 500;
    margin-bottom: 20px;
}
.biz-insights-box-content-sixth {
    background: #fff;
    padding: 30px !important;
}

.biz-insights-box-content-sixth .industrial-title{
    text-transform: uppercase;
    font-size: 18px;
    color: #0a5074;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}
.industrial-content .industrial-content-main-title{
    color: #083952;
    font-weight: 600;
    font-size: 18px;
}
.industrial-content .industrial-content-title{
    text-align: center;
    color: #678899;
    font-size: 18px;
}
.industrial-content .industrial-content-content{
    text-align: center;
    color: #083952;
    font-size: 18px;
}
.biz-insights-box-content-seven {
    background: #f5f5f5;
    padding: 30px !important;
}

.biz-insights-box-content-seven .statement-title{
    text-transform: uppercase;
    font-size: 18px;
    color: #0a5074;
    font-weight: 600;
    margin-top: 20px;
    margin-bottom: 20px;
}
.statement-table tr td {
    color: #678899;
    font-weight: bold;
    font-size: 18px;
}
.all_offerings_button{
    width: 100%;
    background:  rgba(160, 219, 65, 1) !important;
    border: 2px solid rgba(160, 219, 65, 1) !important;
    color: white;
    font-size: 17px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    padding: 8px 10px;
    margin-left: 15px;
    margin-top: 40%;
}

.custom-modal-prosp {
    top: 50%;
    max-width: 70%;
    margin: 0 auto;
    min-height: 300px;
    min-width: 70%;
    height: auto;
    transform: translateY(-50%);
    
}
.user-prosp-header {
    text-align: center;
    
}
.user-prosp-header p {
    font-size: 20px;
    font-weight: bold;
}