
.privacy_policy_content{
    margin: auto;
    height: 100%;
}
.privacy_policy_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}
.text_content{
    margin-bottom: 10px;
}
.text_content_last{
    color: #333;
    margin-bottom: 20px;
    text-align: justify;
}
.text_content strong {
    color: #333333;
}
.privacy_policy_inner_content{
    background-color: #fff;
    overflow-y: scroll;
    height: calc(100% - 0px);
    padding: 15px;
    border-radius: 4px;
    padding-bottom: 0px;
}


@media (max-width: 767px){
    .privacy_policy_content{
        margin-top: 0px;
        padding-bottom: 10px;
    }
}
