.verifypassword-form {
    max-width: 400px;
    margin: auto;
}
.verify_password_content{
    margin: auto;
    height: 100%;
}
.verify_password_inner_content{
    background-color: #fff;
    overflow-y: scroll;
    height: calc(100% - 0px);
    padding: 15px;
    border-radius: 4px;
    padding-bottom: 0px;
}
.enter_password{
    text-align: center;
    margin-bottom: 2.5rem !important;
}
.form-group.change_password_field.verify_password_field {
    margin-bottom: 2.5rem !important;
    position: relative;
}
.form-group.change_password_field.verify_password_field input {
    padding: 0 30px 0 15px;
    height: 50px;
    line-height: 50px;
}
.form-group.change_password_field.verify_password_field input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba(109, 206, 64, .8);
}
.form-group.change_password_field.verify_password_field input::-moz-placeholder { /* Firefox 19+ */
    color: rgba(109, 206, 64, .8);
}
.form-group.change_password_field.verify_password_field input:-ms-input-placeholder { /* IE 10+ */
    color: rgba(109, 206, 64, .8);
}
.form-group.change_password_field.verify_password_field input:-moz-placeholder { /* Firefox 18- */
    color: rgba(109, 206, 64, .8);
}
.form-group.change_password_field.verify_password_field input:focus {
    border-color: rgba(109, 206, 64, .8);
}
.form-group.change_password_field.verify_password_field i {
    position: absolute;
    top: 25px;
    transform: translateY(-50%);
    right: 10px;
    color: rgba(109, 206, 64, .8);
    cursor: pointer;
}

@media (max-width: 767px){
    .verify_password_content{
        margin-top: 0px;
        padding-bottom: 10px;
    }
}
.error_message span{
    color: red !important;
}