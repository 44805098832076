
.signin-page {
    height: 100%;
    background: linear-gradient(180deg, #edf1f4, #ffffff);
    @media screen and (min-width: 300px) and (max-width: 575px) {
        .signin-content {
            padding-left: 10px;
            padding-right: 10px;
            .signin-company-logo {
                img {
                    width: 70%;
                    margin-bottom: 10px;
                    margin-top: 80px;
                }
            }
            .signin-platform-mockup {
                img {
                    display: none;
                }
            }
            .signup-company-logo-dsp{
                text-align: left;
                img {
                    width: 150px;
                    margin-bottom: 10px;
                    margin-top: 15px;
                }
            }
            .signup-platform-mockup-dsp {
                text-align: center;
                .signup-platform-mockup-dsp-welcome-text{
                    font-size: 26px;
                    margin-bottom: 45px;
                }
                img {
                    width: 35%;
                    margin-bottom: 25px; 
                }
                .signup-platform-mockup-dsp-description{
                    font-size: 18px;
                    text-align: justify;
                    text-justify: inter-word;
                }
            }
            .login_content_inner_body {
                padding: 20px;
                margin-top: 20px;
            }
        }
        

    }
    @media screen and (min-width: 576px) {
        .signin-content {
            padding-left: 80px;
            padding-right: 80px;
            .signin-company-logo {
                img {
                    width: 300px;
                    margin-bottom: 50px;
                    margin-top: 30px;
                }
            }
        
            .login_content_inner_body {
                padding: 40px 40px 20px 40px;
                margin-top: 120px;
            }
        }
    }
    .signin-content {
        width: 100%;
        .signin-company-logo {
            text-align: center;
        }
        .signin-platform-mockup {
            text-align: center;
            img {
                width: 100%;
            }
        }
        .signup-company-logo-dsp{
            text-align: left;
            img {
                width: 150px;
                margin-bottom: 10px;
                margin-top: 15px;
            }
        }
        .signup-platform-mockup-dsp {
            text-align: center;
            .signup-platform-mockup-dsp-welcome-text{
                font-size: 26px;
                margin-bottom: 45px;
            }
            img {
                width: 35%;
                margin-bottom: 25px; 
            }
            .signup-platform-mockup-dsp-description{
                height: 30px;
                font-size: 18px;
                text-align: justify;
                text-justify: inter-word;
                text-overflow: ellipsis; 
            }
        }

        .login_content_inner_head {
            margin-bottom: 75px;
        }
        .login_content_inner_head .company_logo {
            background-color: rgba(255,255,255,0.3);
            padding: 5px 15px;
            max-width: 135px;
            width: 100%;
            height: auto !important;
            margin: 0 auto;
        }

        .login_content_inner_body {
            background: #ffffff;
            border-radius: 15px;
            .login_content_inner_body_inner {
                max-width: 500px;
                margin: 0 auto;
                position: relative;
                .top-border {
                    margin: auto;
                    width: 70px;
                    height: 5px;
                    background: #8ec549;
                }
                .login_heading {
                    font-size: 36px;
                    // text-transform: uppercase;
                    padding-bottom: 10px;
                    height: auto;
                    text-align: center;
                    margin-top: 5px;
                }
            }
           
            .login_heading:after {
                content: '';
                display: block;
                height: 1px;
                background-color: #fff;
                max-width: 121px;
                margin: 0 auto;
            }
            .form_content_login {
                margin-top: 0px;
                p:empty {
                    display: none;
                }
            }
            .password-case-sensitive {
                margin-bottom: 15px;
                margin-top: 15px;
                font-size: 16px;
                //margin-left: 20px;
            }
            .form-control {
                height: 60px;
                padding: 10px 20px;
                font-size: 18px;
                background: #f8fafb;
                color: #4a4a4a;
                border-color: #d7d7d7;
                border-radius: 15px;
            }
            .form-control::-webkit-input-placeholder { /* Edge */
                color: #bebdbd;
              }
              
            .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #bebdbd;
            }
              
            .form-control::placeholder {
                color: #bebdbd;
            }
            .form_field_wrapper > div > div {
                position: relative;
                margin-bottom: 15px;
            }
            .form_field_wrapper > div > div:before {
                content: '';
                position: absolute;
                height: 60px;
                background-repeat: no-repeat;
                background-size: 25px;
                background-position: 15px center;
                display: block;
                top:0;
                left:0;
                width: 55px;
            }
            .forget_password { 
                text-align: center;
                margin-top: 20px;
                a {
                    // color: #fff !important;
                    font-size: 18px;
                    text-decoration: none;
                }
            }
            .login_button {
                background: #8bc540;
                color: #ffffff;
                border-radius: 15px;
                width: 100%;
                padding: 10px 15px;
                text-align: center;
                border: none;
                // text-transform: uppercase;
                font-size: 21px;
                display: block;
                margin: 0 auto;
                transition: all 0.2s ease-in;
            }
        }
        .login_content_inner_body_bottom {
            padding: 20px;
            background: #ffffff;
            border-radius: 15px;
            margin-top: 20px;
            text-align: center;
            .not_account_contnet {
                p {
                    margin-bottom: 0;
                    color: #7e807f;
                    font-size: 18px;
                    a {
                        // color: #fff !important;
                        margin-left: 5px;
                    }
                }
                .signup_link{
                    font-size: 16px;
                    font-weight: bold;
                    margin-left: 2px;
                    color: #27cba4 !important;
                    text-transform: uppercase;
                }
            }
        }
        .login_content_form_getapp {
            margin-top: 50px;
            margin-bottom: 20px;
            .getapp_title {
                text-align: center;
                p {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
            .app-download-box {
                .col-6 {
                    padding: 5px;
                }
                .ios-app-image {
                    text-align: right;
                    img {
                        width: 70%;
                    }
                }
                .android-app-image {
                    text-align: left;
                    img {
                        width: 70%;
                    }
                }
            }
            
        }

        // .login_content_inner_body_inner .login_button:not(:disabled):hover,
        // .login_content_inner_body_inner .login_button:not(:disabled):focus {
        //     box-shadow: 0 5px 16px 0 rgba(0,0,0,0.25);
        // }
        
        @media screen and (max-width: 575px) {
            .login_content_inner_head {
                margin-bottom: 50px;
            }
            .login_content_inner_body_inner .user_icon {
                height: 70px;
                width: 70px;
                line-height: 70px;
                margin-bottom: -35px;
            }
            .login_content_inner_body_inner .user_icon i {
                font-size: 2rem;
            }
            .login_content_inner_body_inner .login_heading {
                font-size: 24px;
            }
            .login_content_inner_body_inner .form-control,
            .login_content_inner_body_inner .forget_password {
                margin-bottom: 15px;
            }
            .login_content_inner_body_inner .forget_password a,
            .login_content_inner_body_inner .login_button,
            .login_content_inner_body_inner .not_account_contnet p {
                font-size: 16px;
            }
        }
    }
    
    .error_message span{
        color: red !important;
    }
    .cst_info_alert {
        position: fixed !important;
        top: 25px;
        right: 25px;
        animation: cst-info-alert 0.3s ease-in;
        width: 100%;
        max-width: 320px;
    }
    @keyframes cst-info-alert {
        from {
            transform: translateY(-150px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }

    .mysmartbanner {
        left: 0;
        display: block;
        width: 100%;
        height: 80px;
        line-height: 80px;
        font-family: Open Sans,sans-serif !important;
        background: #f4f4f4;
        z-index: 9998;
        -webkit-font-smoothing: antialiased;
        overflow: hidden;
        -webkit-text-size-adjust: none;
        .smartbanner-container {
            margin: 0 auto;
            padding: 0 5px;
        }
        .smartbanner-close {
            border: 0;
            max-width: 17px;
            width: 100%;
            height: 17px;
            line-height: 17px;
            margin-right: 7px;
            color: #b1b1b3;
            background: #1c1e21;
            text-shadow: 0 1px 1px #000;
            text-decoration: none;
            -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.8), 0 1px 1px hsla(0,0%,100%,.3);
            box-shadow: inset 0 1px 2px rgba(0,0,0,.8), 0 1px 1px hsla(0,0%,100%,.3);
            cursor: pointer;
        }
        .smartbanner-close {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px 0 0;
            font-family: Open Sans,sans-serif !important;
            font-size: 20px;
            text-align: center;
            color: #888;
            text-decoration: none;
            border: 0;
            border-radius: 14px;
            padding: 0 0 1px;
            background-color: transparent;
            -webkit-font-smoothing: subpixel-antialiased;
        }
        .smartbanner-icon {
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        .smartbanner-icon, .smartbanner-info {
            display: inline-block;
            vertical-align: middle;
        }
        .smartbanner-icon {
            width: 57px;
            height: 57px;
            margin-right: 12px;
            -webkit-background-size: cover;
            background-size: cover;
            border-radius: 10px;
        }
        .smartbanner-info {
            color: #ccc;
            text-shadow: 0 1px 2px #000;
        }
        .smartbanner-info {
            white-space: normal;
            width: -webkit-calc(99% - 201px);
            width: -moz-calc(99% - 201px);
            width: calc(99% - 201px);
            font-size: 11px;
            line-height: 1.2em;
            font-weight: 700;
        }
        .smartbanner-title {
            font-size: 13px;
            line-height: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #fff;
            font-weight: 700;
        }
        .smartbanner-author {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .smartbanner-author:empty+.smartbanner-description {
            max-height: 50px;
        }
        .smartbanner-description {
            max-height: 40px;
            overflow: hidden;
        }
        .smartbanner-wrapper {
            max-width: 110px;
            display: inline-block;
            text-align: right;
            width: 100%;
        }
        .smartbanner-button {
            min-width: 12%;
            color: #d1d1d1;
            font-weight: 700;
            padding: 0;
            background: none;
            border-radius: 0;
            -webkit-box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
            box-shadow: 0 0 0 1px #333, 0 0 0 2px #dddcdc;
        }
        .smartbanner-button {
            margin: auto 0;
            height: 24px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-weight: 700;
            color: #6a6a6a;
            text-transform: uppercase;
            text-decoration: none;
            display: inline-block;
            text-shadow: 0 1px 0 hsla(0,0%,100%,.8);
        }
        .smartbanner-button-text {
            text-align: center;
            display: block;
            padding: 0 10px;
            background: #42b6c9;
            background: -webkit-gradient(linear,left top, left bottom,from(#42b6c9),to(#39a9bb));
            background: -webkit-linear-gradient(top,#42b6c9,#39a9bb);
            background: -moz-linear-gradient(top,#42b6c9,#39a9bb);
            background: linear-gradient(180deg,#42b6c9,#39a9bb);
            text-transform: none;
            text-shadow: none;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
    }
    .mysmartbanner-top {
        position: absolute;
        top: 0;
    }
    .mysmartbanner-android {
        background: #3d3d3d url(data:image/gif;base64,R0lGODlhCAAIAIABAFVVVf///yH5BAEHAAEALAAAAAAIAAgAAAINRG4XudroGJBRsYcxKAA7);
        -webkit-box-shadow: inset 0 4px 0 #88b131;
        box-shadow: inset 0 4px 0 #88b131;
        line-height: 82px;
    }
    .mysmartbanner-ios {
        background: #f2f2f2;
        -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.2);
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        line-height: 80px;
    }
    .mysmartbannerios {
        left: 0;
        display: block;
        width: 100%;
        height: 80px;
        line-height: 80px;
        font-family: Open Sans,sans-serif !important;
        background: #f4f4f4;
        z-index: 9998;
        -webkit-font-smoothing: antialiased;
        overflow: hidden;
        -webkit-text-size-adjust: none;
        .smartbanner-container {
            margin: 0 auto;
            padding: 0 5px;
        }
        .smartbanner-close {
            border: 0;
            width: 18px;
            height: 18px;
            line-height: 18px;
            font-family: Arial;
            color: #888;
            text-shadow: 0 1px 0 #fff;
            -webkit-font-smoothing: none;
        }
        .smartbanner-close {
            display: inline-block;
            vertical-align: middle;
            margin: 0 5px 0 0;
            font-family: ArialRoundedMTBold,Arial;
            font-size: 20px;
            text-align: center;
            color: #888;
            text-decoration: none;
            border: 0;
            border-radius: 14px;
            padding: 0 0 1px;
            background-color: transparent;
            -webkit-font-smoothing: subpixel-antialiased;
        }
        .smartbanner-icon {
            background-color: transparent;
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        .smartbanner-icon, .smartbanner-info {
            display: inline-block;
            vertical-align: middle;
        }
        .smartbanner-icon {
            width: 57px;
            height: 57px;
            margin-right: 12px;
            -webkit-background-size: cover;
            background-size: cover;
            border-radius: 10px;
        }
        .smartbanner-info {
            color: #6a6a6a;
            text-shadow: 0 1px 0 hsla(0,0%,100%,.8);
            font-weight: 300;
        }
        .smartbanner-info {
            white-space: normal;
            width: -webkit-calc(99% - 201px);
            width: -moz-calc(99% - 201px);
            width: calc(99% - 201px);
            font-size: 11px;
            line-height: 1.2em;
            font-weight: 700;
        }
        .smartbanner-title {
            font-size: 13px;
            line-height: 18px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: #4d4d4d;
            font-weight: 500;
        }
        .smartbanner-author {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .smartbanner-author:empty+.smartbanner-description {
            max-height: 50px;
        }
        .smartbanner-description {
            max-height: 40px;
            overflow: hidden;
        }
        .smartbanner-wrapper {
            max-width: 110px;
            display: inline-block;
            text-align: right;
            width: 100%;
        }
        .smartbanner-button {
            padding: 0 10px;
            font-size: 15px;
            min-width: 10%;
            font-weight: 400;

            margin: auto 0;
            height: 24px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            font-weight: 700;
            color: #6a6a6a;
            text-transform: uppercase;
            text-decoration: none;
            display: inline-block;
            text-shadow: 0 1px 0 hsla(0,0%,100%,.8);
            color: black !important;
        }
        // .smartbanner-button-text {
        //     text-align: center;
        //     display: block;
        //     padding: 0 10px;
        //     background: #42b6c9;
        //     background: -webkit-gradient(linear,left top, left bottom,from(#42b6c9),to(#39a9bb));
        //     background: -webkit-linear-gradient(top,#42b6c9,#39a9bb);
        //     background: -moz-linear-gradient(top,#42b6c9,#39a9bb);
        //     background: linear-gradient(180deg,#42b6c9,#39a9bb);
        //     text-transform: none;
        //     text-shadow: none;
        //     -webkit-box-shadow: none;
        //     box-shadow: none;
        // }
    }
    
}
