
.place_order_content{
    margin: auto;
    height: 100%;
    padding-top: 1rem;
    background-color: #fff;
}
.place_order_heading{
    height: 50px;
    text-align: center;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
    margin-bottom:15px;
}
.place_order_inner_content{
    margin-top: 15px;
    margin: auto;
    background-color: #fff;
    padding: 15px 0;
    height: calc(100% - 73px);
    overflow-y: auto;
}
.order_user_images{
    width: 30%;
}
.success-placeorder-icon {
    text-align: center;
}
.success-placeorder-icon img {
    width: 150px;
    margin: 16px 0px 32px 0px;
}
.company_placeorder_tag{
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 0px;
    color: #678899;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 10px;
}
.company_placeorder_account{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 17px;
    margin-left: 5px;
}
.company_placeorder_name{
    margin-bottom: 5px !important;
    font-weight: 500;
    font-size: 17px;
    color: #083952 !important;
}
.account_placeorder_type{
    margin-bottom: 0px !important;
    font-weight: 500 !important;
    font-size: 15px;
    color: #678899 !important;
}
.account_open_placeorder_date{
    float: right;
    margin-bottom: 0px !important;
    /* font-weight: 500; */
    font-size: 17px;
    color: #336e8d;
}
.price_level_placeorder_type{
    margin-bottom: 3px !important;
    font-weight: 500 !important;
    font-size: 15px;
    color: #678899 !important;
}
.amount_placeorder{
    float: right;
    margin-bottom: 0px !important;
    /* font-weight: 500; */
    /* font-size: 17px; */
    color: #336e8d !important;
}
.investment_status{
    margin-bottom: 0px !important;
    font-weight: 500 !important;
    color: #678899 !important;
    font-size: 15px;
}
.investment_amount{
    float: right !important;
}
.investment_amount input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
}
.investment_amount input[type=text]:focus{
    outline: none;
}
.place_investment_amount.rt-input-input.investment_amount input[type=text] {
    padding-left: 5px;
}
.place_investment_amount.rt-input-input.investment_amount:after {
    width: initial;
    height: initial;
    top: 50%;
    left: -15px;
}
.share_status{
    margin-bottom: 0px !important;
    font-weight: 500 !important;
    color: #678899 !important;
    font-size: 15px;
}
.share_amount{
    float: right;
    /* font-weight: 500 !important; */
    color: #336e8d;
    font-size: 17px;
    word-break: break-all;
    margin-bottom: 0px;
    margin-right: 0px !important;
}
.investment_content{
    margin-bottom: 10px;
}
.modify_btn{
    margin: auto;
}
.mt_10px{
    margin-top: 10px;
}
.prospectus_link{
    margin-bottom: 5px;
    font-weight: 500;
}
.modify_btn .btn{
    color: #fff;
    background-color: #5fcc40 !important;
    width: 100%;
    margin-top: 0;
    font-size: 18px;
    background-image: linear-gradient(to right,rgba(160, 219, 65, 1) 0%,rgba(109, 206, 64, 1) 100%);
    color: #fff;
    border: none;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
    padding: 10px;
}
.modify_btn a{
    color: #fff !important;
    text-decoration: none !important;
}
.review_order_text{
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
}
.review_order_text_content{
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}
.accept_condition{
    position: unset !important;
    width: 10% !important;
    margin-top: 4px !important;
    height: 18px !important;
}
.checkbox_content{
    text-align: center;
    width: 100%;
}
.checkbox_content_inner{
    position: relative;
    top: -5px;
}
.rule_name{
    color: rgba(68,198,210,1);
    text-decoration: underline;
    cursor: pointer;
    margin-left: 3px;
}
.buy_offer{
    color: #fff;
    border-color: #5fcc40 !important;
    width: 100%;
    font-size: 18px;
}
.buy_offer .btn-primary{
    margin-top: 0px;
    line-height: 1;
    height: 45px;

}
.raise_score_image{
    text-align: center;
}
.raise_score_image img{
    width: 25%;
}
.investor_score_text{
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 15px;
}
.no_order_btn{
    cursor: not-allowed !important;
}
.modal_css{
    height: 85vh;
    overflow: auto;
    text-align: justify;
    max-width: calc(100vh);
    margin: 0 auto;
    width: 100vh;
}

@media (max-width: 767px){
    .place_order_content{
        margin-top: 0px;
    }
    .share_amount{
        word-break: break-all;
        margin-right: 0px;
    }
    .place_order_inner_content{
        width: 100%;
        margin: auto;
        margin-left: 0px;
    }
    .account_placeorder_type{
        font-size: 16px
    }
    .account_open_placeorder_date{
        font-size: 16px;
    }
    .investment_amount input[type=text]{
        width: 100%
    }
    .modify_btn .btn{
        padding: 0 0;
    }
    .company_placeorder_tag{
        font-size: 16px;
    }
    .order_user_images{
        width: 100%
    }
}
.place_order_inner_content_inner > * {
    margin-bottom: 5px !important;
}
.iframe-place {
    border: none;
    border-top: 1px solid #ccc;
    width: 100%;
    margin-bottom: -22PX;
    margin-top: 10px;
    height: calc(100% - 227px);
    min-height: 200px;
}
.buying_power{
    margin-bottom: 0px !important;
    font-weight: 500 !important;
    font-size: 17px;
    color: #678899 !important;
}
.buying_power_amount {
    float: right;
    margin-bottom: 0px !important;
    /* font-weight: 500 !important; */
    font-size: 15px;
    color: #336e8d !important;
}
.prospectus{
    padding-top: 10px;
}
.prospectus_link_url{
    text-decoration: underline;
    color: blue;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: 500;
}
.success_modal{
    text-align: center;
}
.header_text{
    font-size: 19px;
    margin-bottom: 20px;
}
.congrats_text{
    color: rgba(8,57,82,1);
    margin-bottom: 15px;
}
.modal_content{
    color: rgba(74,74,74,1);
    font-size: 14px;
}
.order_amount_offering_name{
    font-size: 25px;
}
.place_order_prospects{
    text-align: right;
}
.styles_modal__gNwvD{
    max-width: fit-content;
}

.order-success-message {
    line-height: 1.6;
    max-width: 100%;
    padding: 0% 10%;
}
.order-success-message b{
    font-size: 18px;
}