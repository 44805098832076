
.message_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.message_box{
    margin-left: -10px;
    margin-right: -15px;
    overflow: scroll;
    height: calc(100% - 0px);
}
.active_message_box{
    overflow-x: hidden;
    padding: 20px 30px 0px 30px;
    overflow-y: auto;
    height: 100%;
}
.active_message_box::-webkit-scrollbar {
    width: 6px;
}
.msg_card_body td{
    padding: 0px;
}
.msg_card_body .templateContainer{
    width: 100% !important;
    max-width: 100% !important;
}
.msg_card_body #templatePreheader {
    
}
.loadmore-option {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.loadmore-option .loadmorebtn {
    margin: auto;
    color: #8DC73F;
}
.loadmore-option .loadmorebtn:focus {
    box-shadow: none;
}
.email-push-indication {
    /* float: right; */
    font-size: 14px;
    color: #8DC73F;
    /* margin-top: -3px; */
    text-transform: capitalize;
}
/* body,
html {
    height: 100%;
    margin: 0;
    background: #7F7FD5;
    background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
    background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
} */
.chat {
    /* margin-top: auto;
    margin-bottom: auto; */
}
.card {
    height: calc(100vh - 150px);
    border-radius: 4px !important;
    overflow: hidden;
    /* background-color: rgba(0, 0, 0, 0.4) !important; */
}
.contacts_body {
    padding: 0.75rem 0 !important;
    overflow-y: auto;
    white-space: nowrap;
}
.msg_card_body {
    overflow-y: auto;
}
.card-header {
    border-radius: 4px 4px 0 0 !important;
    border-bottom: 0 !important;
}
.card-footer {
    border-radius: 0 0 4px 4px !important;
    border-top: 0 !important;
}
.container {
    align-content: center;
}
.search {
    border-radius: 4px 0 0 4px !important;
    /* background-color: rgba(0, 0, 0, 0.3) !important; */
    border: 1px solid #ddd !important;
    color: #000 !important;
    font-size: 14px !important;
}
.search:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.type_msg {
    /* background-color: rgba(0, 0, 0, 0.3) !important; */
    border: 0 !important;
    color: #000 !important;
    height: 60px !important;
    overflow-y: auto;
}
.type_msg:focus {
    box-shadow: none !important;
    outline: 0px !important;
}
.attach_btn {
    border-radius: 15px 0 0 15px !important;
    /* background-color: rgba(0, 0, 0, 0.3) !important; */
    border: 0 !important;
    color: #000 !important;
    cursor: pointer;
}
.send_btn {
    border-radius: 0 15px 15px 0 !important;
    /* background-color: rgba(0, 0, 0, 0.3) !important; */
    border: 0 !important;
    color: #000 !important;
    cursor: pointer;
}
.search_btn {
    border-radius: 0 4px 4px 0 !important;
    /* background-color: rgba(0, 0, 0, 0.3) !important; */
    border: 0 !important;
    color: #000 !important;
    cursor: pointer;
}
.contacts {
    list-style: none;
    padding: 0;
}
.contacts li {
    width: 100% !important;
    padding: 5px 10px;
    /* margin-bottom: 15px !important; */
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
.active-index {
    /* background-color: rgba(0, 0, 0, 0.3); */
    background-color: #f0f0f0;
}
.user_img {
    height: 50px;
    width: 50px;
    border: 1.5px solid #f5f6fa;
}
.user_img_msg {
    height: 40px;
    width: 40px;
    border: 1.5px solid #f5f6fa;
}
.img_cont {
    position: relative;
    height: 50px;
    width: 50px;
}
.img_cont_msg {
    height: 40px;
    width: 40px;
}
.online_icon {
    position: absolute;
    height: 12px;
    width: 12px;
    background-color: #4cd137;
    border-radius: 50%;
    bottom: 0.2em;
    right: 0em;
    border: 1.5px solid white;
}
.offline {
    background-color: #c23616 !important;
}
.user_info {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    word-break: break-all;
    white-space: normal;
}
.user_info span {
    font-size: 14px;
    color: #000;

    width: 100%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.user_info p {
    font-size: 12px;
    /* color: rgba(255, 255, 255, 0.6); */
    color: #999;
    margin-top: 0px;
    margin-bottom: 0px;
}
.user_info_detail span {
    font-size: 16px;
    color: #000;
}
.user_info_detail p {
    font-size: 12px;
    /* color: rgba(255, 255, 255, 0.6); */
    color: #999;
    margin-bottom: 0px;
}

.msg_cotainer {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background-color: #fff;
    padding: 5px 10px;
    position: relative;
    min-width: 80px;
    font-size: 14px;
}
.msg_cotainer_send {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    border-radius: 8px;
    background-color: #ddd;
    padding: 5px 10px;
    position: relative;
    min-width: 80px;
    font-size: 14px;
}
.msg_time {
    position: absolute;
    left: 0;
    bottom: -17px;
    /* color: rgba(255, 255, 255, 0.5); */
    color: #999;
    font-size: 10px;
}
.msg_time_send {
    position: absolute;
    right: 0;
    bottom: -15px;
    /* color: rgba(255, 255, 255, 0.5); */
    color: #999;
    font-size: 10px;
}
.msg_head {
    position: relative;
}

@media(max-width: 576px) {
    .contacts_card {
        margin-bottom: 15px !important;
    }
}
