
.order_content{
    margin: auto;
    border-bottom: none;
    height: 100%;
    background: #fff;
    padding: 15px 15px;
}
.order_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
}
.order-info-btn {
    background: transparent;
    /* padding: 0px 8px; */
    border-radius: 50px;
    /* border: 1px solid #999; */
    margin-left: 10px;
    font-size: 14px;
    color: #999;
    cursor: pointer;    
    position: relative;
    display: inline-block;
}
.order-info-btn .tooltiptext {
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 50%;
    margin-left: -110px;
}
.active_order_tooltip {
    position: relative;
}
.active_order_tooltiptext {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 70%;
    left: 26%;
}
@media (min-width:320px) and (max-width:575px) {
    .active_order_tooltiptext {
        left: 0% !important;
    }
}
.active_order_tooltip:hover .active_order_tooltiptext {
    visibility: visible;
}
.order-info-btn:hover .tooltiptext {
    visibility: visible;
}
.cancelled_order_style {
    background: #e8e8e8;
}
.closed_order_style {
    background: #c5c5c5;
}
.order_box{
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
}
.order_box > .row,
.order_box > .row > div {
    height: 100%;
}
.closed_cancel_order_box,
.active_order_box_wrapper {
    height: calc(100% - 10px);
}
.active_order_box,
.closed_order_box {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 55px);
}

.active_order_box::-webkit-scrollbar {
    width: 6px;
}
.closed_order_box::-webkit-scrollbar {
    width: 6px;
}
.active_order{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    border: 2px solid lightgray;
    padding-bottom: 6px;
}
.closed_order{
    text-align: center;
    font-size: 17px;
    font-weight: 500;
    border: 2px solid lightgray;
    padding-bottom: 6px;
}
.order_list p {
    color: #666666;
    margin-bottom: 5px;
}
.order_list a:hover{
    text-decoration: none
}
.order_box_inner_content {
    padding: 15px;
    border: 1px solid lightgray;
    /* cursor: pointer; */
}
.order_box_inner_content_offering {
    cursor: pointer;
}
.order_box_inner_content_offering:hover {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -ms-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -o-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
}
.active-index-order {
    -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -ms-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    -o-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.19), 0 0 6px rgba(0, 0, 0, 0.23);
}
.order_box_inner_content.no-bdr {
    border: none;
}
.order_box_inner_content:not(:last-child) {
    border-bottom: none;
}
.order_box_inner_content .bdr-success,
.order_box_inner_content .bdr-danger, .order_box_inner_content .bdr-light  {
    border: 1px solid;
    margin: -15px;
    padding: 15px;
}
.order_box_inner_content .bdr-success,
.order_box_inner_content .bdr-danger {
    border-bottom: none;
}
.order_box_inner_content .bdr-light {
    border-color: lightgray;
}
.order_box_inner_content .bdr-success {
    border-color: #28a745;
}
.order_box_inner_content .bdr-danger {
    border-color: #dc3545;
}
.order_box_inner_content:last-child > .bdr-success {
    border-bottom: 1px solid #28a745;
}
.order_box_inner_content:last-child > .bdr-danger {
    border-bottom: 1px solid #dc3545;
}

.company_order_name{
    margin-bottom: 0px !important;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
}
.company_order_tag{
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 0px;
}
.company_order_account{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.account_order_type{
    margin-bottom: 5px !important;
    font-weight: 500;
    font-size: 15px;
}
.account_open_order_date{
    float: right;
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.price_level_order_type{
    margin-bottom: 7px !important;
    font-weight: 500;
    font-size: 15px;
}
.amount_order{
    float: right;
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}

@media (max-width: 767px){
    .active_order{
        font-weight: bold;
    }
    .closed_order_box{
        margin-bottom: 8px;
    }
    .closed_order{
        font-weight: bold;
    }
    .order_content{
        padding-bottom: 15px;
        border-bottom: none;
    }
    .closed_cancel_order_box{
        margin-top: 10px;
    }
    .active_order_box{
        overflow-x: hidden;
    }
    .closed_order_box{
        overflow-x: hidden;
    }
    .order_box > .row > div {
        height: 100%;
    }
    .order_images {
        width: 70px;
        height: 100px;
        object-fit: contain;
        object-position: center;
    }
}

@media (min-width:320px) and (max-width:375px) {
    .order_images {
        width: 42px !important;
    }
}

.section_title {
    color: #1c1c1c;
    margin-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
}
.section_title:after {
    content: '';
    height: 1px;
    width: 100%;
    max-width: 135px;
    margin: 10px auto 0;
    display: block;
    background-color: #1c1c1c;
}
.no_orders{
    text-align: center;
}
.header_content{
    padding-top: 20px;
}
.order_images{
    height: 100px;
    object-fit: contain;
    width: 70px;
    object-position: center;
}
.order_list p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0 !important;
}
.order_list p span {
    margin-bottom: 0 !important;
}
.order_list_two p .account_open_order_date {
    font-weight: normal !important;
    color: #666666 !important;
    font-size: 16px !important;
}