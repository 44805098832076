
.order_detail_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    margin: auto;
    margin-bottom: 10px;
    text-transform: capitalize;
    position: relative;
}
.company_order_tag{
    font-weight: bold !important;
    font-size: 27px !important;
    margin-bottom: 0px !important;
    color: #678899 !important;
    text-align: center;
}
.order_details_box_inner_content{
    background: #fff;
    margin-top: 10px;
    padding-bottom: 15px;
    margin: auto;
    height: calc(100% - 73px);
    overflow-y:  auto;
}
.oeder_user_images{
    width: 45%;
}
.modify_order_button{
    margin-bottom: 15px;
    text-align: center;
}
.modify_order_button .btn{
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
}
.modify_order_button a{
    color: #fff !important;
    text-decoration: none !important;
}
.order_details_btn{
    margin-top: 20px;
}
.share_it{
    margin-top: 10px;
}


@media (max-width: 767px){
    .modify_order_button .btn{
        padding: 0 0;
    }
    .order_detail_heading{
        width: 100%
    }
    .add-on{
        width: 100% !important;
    }
}

.pt-15px {
    padding-top: 15px;
}
@media screen and (max-width: 1199px) {
    .modify_order_button_wrapper {
        margin-top: 15px;
    }
}
@media screen and (max-width: 479px) {
    .order_details_box_inner_content > .row > div:nth-child(1),
    .order_details_box_inner_content > .row > div:nth-child(2) {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.order_details {
    height: 100%;
    background: #fff;
    padding-top: 1rem;
}
.delete_order_confirm_text{
    color: rgba(8,57,82,1);
    text-align: center;
    font-size: 19px;
}
.cancel_order_content{
    color: rgba(74,74,74,1);
    font-size: 18px;
}
.prospectus_icon_text{
    color: #44C6D2;
    font-weight: 500;
    cursor: pointer;
    float: right;
    display: flex;
}
.account_open_order_date{
    float: right;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
    font-size: 17px;
    color: #678889 !important;
}
@media screen and (max-width: 575px) {
    .company_order_tag {
        text-align: left;
    }
    .order_details_box_inner_content {
        height: calc(100% - 88px);
    }
}

.account_open_order_date_two {
    color: #336e8d !important;
    font-weight: normal !important;
    font-size: 16px;
}