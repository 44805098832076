


.sendsignupemail-page {
    height: 100%;
    background: linear-gradient(180deg, #edf1f4, #ffffff);
    @media screen and (min-width: 300px) and (max-width: 575px) {
        .sendsignupemail-content {
            padding-left: 10px;
            padding-right: 10px;
            .sendsignupemail-company-logo {
                img {
                    width: 70%;
                    margin-bottom: 10px;
                    margin-top: 80px;
                }
            }
            .sendsignupemail-platform-mockup {
                img {
                    display: none;
                }
            }
            .sendsignupemail_content_inner_body {
                padding: 20px;
                margin-top: 20px;
            }
        }
    }
    @media screen and (min-width: 576px) {
        .sendsignupemail-content {
            padding-left: 80px;
            padding-right: 80px;
            .sendsignupemail-company-logo {
                img {
                    width: 300px;
                    margin-bottom: 50px;
                    margin-top: 30px;
                }
            }
            .sendsignupemail_content_inner_body {
                padding: 40px 40px 20px 40px;
                margin-top: 120px;
            }
        }
    }
    .sendsignupemail-content {
        width: 100%;
        .sendsignupemail-company-logo {
            text-align: center;
        }
        .sendsignupemail-platform-mockup {
            text-align: center;
            img {
                width: 100%;
            }
        }

        .sendsignupemail_content_inner_body {
            background: #ffffff;
            border-radius: 15px;
            .sendsignupemail_content_inner_body_inner {
                max-width: 500px;
                margin: 0 auto;
                position: relative;
                .top-border {
                    margin: auto;
                    width: 70px;
                    height: 5px;
                    background: #8ec549;
                }
                .sendsignupemail_heading {
                    font-size: 36px;
                    // text-transform: uppercase;
                    padding-bottom: 10px;
                    height: auto;
                    text-align: center;
                    margin-top: 5px;
                    .email-verification-title{
                        text-align: center;
                        font-size: 36px;
                        color: #fff;
                    }
                }
            }
            
            .sendsignupemail_heading:after {
                content: '';
                display: block;
                height: 1px;
                background-color: #fff;
                max-width: 121px;
                margin: 0 auto;
            }
            .form_content_sendsignupemail {
                margin-top: 0px;
                p:empty {
                    display: none;
                }
            }

            .form_field_wrapper > div > div {
                position: relative;
                margin-bottom: 15px;
            }

            .form-control{
                height: 45px;
            }

            .forgot_button {
                background: #27cba4;
                color: #ffffff;
                border-radius: 8px;
                width: 100%;
                //padding: 10px 15px;
                text-align: center;
                border: none;
                font-size: 21px;
                display: block;
                margin: 0 auto;
                transition: all 0.2s ease-in;
            }


            .text-decoration-none {
                text-decoration: none !important;
            }
            .sendsignupemail_button {
                background: transparent !important;
                border-color: transparent !important;
                color: #67b1c7 !important;
                font-size: 18px !important;
            }
            .email-verification-title{
                text-align: center;
                font-size: 30px;
                margin-top: 20px;
            }
            .email-verification-caption{
                text-align: center;
                font-size: 18px;
                margin-top: 30px;
                margin-bottom: 20px;
            }
            .resend-email-btn{
                background: #27cba4;
                color: #ffffff;
                border-radius: 15px;
                width: 100%;
                padding: 10px 15px;
                text-align: center;
                border: none;
                font-size: 21px;
                display: block;
                margin: 0 auto;
                transition: all 0.2s ease-in;
            }
            .resend-email-btn:focus{
                outline: none;
            }
        }

        .sendsignupemail_content_form_getapp {
            margin-top: 50px;
            margin-bottom: 20px;
            .getapp_title {
                text-align: center;
                p {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
            .app-download-box {
                .col-6 {
                    padding: 5px;
                }
                .ios-app-image {
                    text-align: right;
                    img {
                        width: 70%;
                    }
                }
                .android-app-image {
                    text-align: left;
                    img {
                        width: 70%;
                    }
                }
            }
            
        }
        
        @media screen and (max-width: 575px) {
            .sendsignupemail_content_inner_head {
                margin-bottom: 50px;
            }
            .sendsignupemail_content_inner_body_inner .sendsignupemail_heading {
                font-size: 24px;
            }
            .sendsignupemail_content_inner_body_inner .form-control,
            .sendsignupemail_content_inner_body_inner .forget_password {
                margin-bottom: 15px;
            }
            .sendsignupemail_content_inner_body_inner .forget_password a,
            .sendsignupemail_content_inner_body_inner .sendsignupemail_button,
            .sendsignupemail_content_inner_body_inner .not_account_contnet p {
                font-size: 16px;
            }
        }
    }
    
    .error_message span{
        color: red !important;
    }
    .cst_info_alert {
        position: fixed !important;
        top: 25px;
        right: 25px;
        animation: cst-info-alert 0.3s ease-in;
        width: 100%;
        max-width: 320px;
    }
    @keyframes cst-info-alert {
        from {
            transform: translateY(-150px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}