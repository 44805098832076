.brokerage_content{
    margin: auto;
    height: 100%;
    overflow-y: auto;
    background: #fff;
}
.back_arrow_icon_brokerage {
    width: 100%;
    max-width: 40px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
}
.brokerage_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}
.restriction_rule{
    margin-top: 10px;
    background-color: #fff;
    border-radius: 4px;
    padding: 10px;
    text-align: justify;
    font-size: 14px;
    overflow: auto;
    height: 300px;
}
.first_restriction_rule_heading{
    font-weight: 700;
    font-size: 16px;
}
.heading_content{
    margin-bottom: 10px;
    font-weight: 500;
}
.subheading_content{
    margin-bottom: 10px !important;
}
.resticted_content_text{
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    border-radius: 4px;
}
.resticted_content_text .heading_content {
    margin-left: 10px;
    padding-top: 10px;
}
.learn_more span{
    color: blue !important;
    margin-left: 3px;
    font-weight: 500;
}
.learn_more span:hover{
    text-decoration: underline !important;
    cursor: pointer;
}
.confirm_check_restriction{
    height: 18px !important;
}
.reconfirm_check_restriction{
    height: 17px !important;
}
.restriction_text{
    margin-bottom: 10px;
    font-size: 14px;
    text-align: justify;
}
.confirm_restriction{
    color: #fff;
    border-color: #5fcc40 !important;
    width: 100%;
    padding-bottom: 15px;
    margin: 0 auto;
}
.confirm_restriction a {
    color: #fff !important;
    display: block;
}
.confirm_restriction a:hover {
    text-decoration: none !important;
}
.confirm_restriction .btn-primary{
    margin-top: 0px;
    line-height: 1;
    background: linear-gradient(to left top, rgba(76,199,63,1), rgba(183,225,67,1));
    height: 45px;
}
.connect_create_content{
    background-color: #fff;
    padding: 15px;
}
.have_account_content{
    line-height: 5;
    text-align: center;
    font-size: 15px;
}
.not_account_content{
    line-height: 5;
    text-align: center;
    font-size: 15px;
    color: black;
}
.modal_confirm_text{
    margin-top: 25px;
}
button:focus{
    outline: none !important
}
.restriction_rule::-webkit-scrollbar {
    width: 6px;
}
.btn-warning{
    height: 45px;
    color: #fff !important;
}
.btn-warning:hover{
    color: #ffc107 !important;
    background-color: #ffc107 !important;
    color: #fff !important;
}
.cancel_button{
    width: 100%;
    margin-top: 10px;
}
.err_msg{  
    text-align: center;
    line-height: 5;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 18px;
}

@media (max-width: 767px){
    .confirm_check_restriction{
        height: 15px !important;
    }
    .reconfirm_check_restriction{
        height: 15px !important;
    }
    .confirm_restriction{
        margin-bottom: 15px;
    }
    .have_account_content{
        line-height: 1;
        padding-top: 20px;
        font-weight: 500;
    }
    .not_account_content{
        line-height: 1;
        font-weight: 500;
    }
    .confirm_check_restriction,
    .reconfirm_check_restriction {
        width: auto !important;
    }
    .restriction_text {
        margin-left: 20px;
    }
}
.Loader__background{
    z-index: 9999 !important;
    background-color: rgba(0,0,0,0.7) !important;
}
.divider_line {
    color: #1c1c1c !important;
    padding-bottom: 15px !important;
    margin-bottom: 15px !important;
    position: relative;
}
.divider_line::after {
    content: '';
    height: 1px;
    width: 100%;
    max-width: 135px;
    margin: 0 auto;
    display: block;
    background-color: #1c1c1c;
    margin-top: 30px;
}
.brokerage-account-link-btns {
    display: flex;
    align-items: center;
    justify-content: center;
}