html, body, #root {
    height: 100%;
}
// html, body, #root div{
//     height: 100%;
// }
.okbtn-attestation {
    min-width: 100px;
    background: #a0db41;
    color: #fff;
    border-radius: 0;
    font-size: 17px;
    padding: 10px;
}