
.dealer_connect_content{
    width: 90%;
}
.dealer_connect_heading{
    background: linear-gradient(to left top, rgba(76,199,63,1), rgba(183,225,67,1));
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}
.form_content_dealer_connect{
    margin-top: 15px;
}
.btn-primary{
    color: #fff;
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
    width: 100%;
    margin-top: 10px;
}
.form-control:focus{
    box-shadow: none !important;
}
.confirm_order_button {
    margin-bottom: 15px;
}
.reconfirm__content .reconfirm__text {
    color: #7b7b7b;
}
.reconfirm__content p:not(.reconfirm__text) {
    color: #666666;
    font-weight: normal !important;
}

@media (max-width: 767px){
    .dealer_connect_content{
        width: 100%;
        margin-top: 0px;
        padding-right: 15px;
    }
}

@media screen and (max-width: 575px) {
    .confirm_order_button_wrapper .confirm_order_button {
        display: inline-block;
        vertical-align: top;
    }
    .confirm_order_button_wrapper .confirm_order_button:nth-child(2) {
        margin-left: 15px;
    }
}
@media screen and (min-width: 480px) and (max-width: 575px) {
    .confirm_order_button_wrapper .confirm_order_button {
        width: calc((100% - 30px) / 3);
    }
    .confirm_order_button_wrapper .confirm_order_button:nth-child(2) {
        margin-right: 15px;
    }
}
@media screen and (max-width: 479px) {
    .confirm_order_button_wrapper .confirm_order_button:not(:nth-child(3)) {
        width: calc((100% - 15px) / 2);
    }
    .confirm_order_button_wrapper .confirm_order_button:nth-child(3) {
        width: 100%;
    }
}