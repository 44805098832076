
// .forgetpassword_content{
//     margin: auto;
// }
// .forgetpassword_heading{
//     text-align: center;
//     height: 50px;
//     line-height: 2.2;
//     font-size: 20px;
//     color: #fff;
// }
// .form_content_forgetpassword{
//     margin-top: 15px;
// }
// .btn-primary{
//     color: #fff;
//     background-color: #5fcc40 !important;
//     border-color: #5fcc40 !important;
//     width: 100%;
//     margin-top: 10px;
// }
// .company_logo{
//     height: 75px;
//     text-align: center;
// }
// .form-control:focus{
//     box-shadow: none !important;
// }

// .mt-35px {
//     margin-top: 35px;
// }
// .text-decoration-none {
//     text-decoration: none !important;
// }
// .form_content_forgotpassword_code {
//     text-align: center;
//     color: #fff;
//     margin-top: 30px;
// }
// .form_content_forgotpassword_code span {
//     color: #5fcc40;
//     cursor: pointer;
// }
// .reset_field_wrapper > div > div {
//     position: relative;
//     margin-bottom: 35px;
// }
// .reset_field_wrapper > div > div:before {
//     content: '\f00a';
//     position: absolute;
//     height: 40px;
//     font-family: FontAwesome;
//     background-repeat: no-repeat;
//     background-size: 25px;
//     background-position: 15px center;
//     display: block;
//     top: 12px;
//     left: 15px;
//     width: 40px;
//     color: #999999;
//     font-size: 24px;
//     margin: auto;
// }

.resetcode-page {
    height: 100%;
    background: linear-gradient(180deg, #edf1f4, #ffffff);
    @media screen and (min-width: 300px) and (max-width: 575px) {
        .resetcode-content {
            padding-left: 10px;
            padding-right: 10px;
            .resetcode-company-logo {
                img {
                    width: 70%;
                    margin-bottom: 10px;
                    margin-top: 80px;
                }
            }
            .resetcode-platform-mockup {
                img {
                    display: none;
                }
            }
            .resetcode_content_inner_body {
                padding: 20px;
                margin-top: 20px;
            }
        }
    }
    @media screen and (min-width: 576px) {
        .resetcode-content {
            padding-left: 80px;
            padding-right: 80px;
            .resetcode-company-logo {
                img {
                    width: 300px;
                    margin-bottom: 50px;
                    margin-top: 30px;
                }
            }
            .resetcode_content_inner_body {
                padding: 40px 40px 20px 40px;
                margin-top: 120px;
            }
        }
    }
    .resetcode-content {
        width: 100%;
        .resetcode-company-logo {
            text-align: center;
        }
        .resetcode-platform-mockup {
            text-align: center;
            img {
                width: 100%;
            }
        }

        .resetcode_content_inner_body {
            background: #ffffff;
            border-radius: 15px;
            .resetcode_content_inner_body_inner {
                max-width: 500px;
                margin: 0 auto;
                position: relative;
                .top-border {
                    margin: auto;
                    width: 70px;
                    height: 5px;
                    background: #8ec549;
                }
                .resetcode_heading {
                    font-size: 36px;
                    // text-transform: uppercase;
                    padding-bottom: 10px;
                    height: auto;
                    text-align: center;
                    margin-top: 5px;
                }
            }
           
            .resetcode_heading:after {
                content: '';
                display: block;
                height: 1px;
                background-color: #fff;
                max-width: 121px;
                margin: 0 auto;
            }
            .form_content_resetcode {
                margin-top: 0px;
                p:empty {
                    display: none;
                }
            }
            .form-control {
                height: 60px;
                padding: 10px 20px;
                font-size: 18px;
                background: #f8fafb;
                color: #4a4a4a;
                border-color: #d7d7d7;
                border-radius: 15px;
            }
            .form-control::-webkit-input-placeholder { /* Edge */
                color: #bebdbd;
              }
              
            .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #bebdbd;
            }
              
            .form-control::placeholder {
                color: #bebdbd;
            }
            .form_field_wrapper > div > div {
                position: relative;
                margin-bottom: 15px;
            }
            .form_field_wrapper > div > div:before {
                content: '';
                position: absolute;
                height: 60px;
                background-repeat: no-repeat;
                background-size: 25px;
                background-position: 15px center;
                display: block;
                top:0;
                left:0;
                width: 55px;
            }
            .forget_password { 
                text-align: center;
                margin-top: 20px;
                a {
                    // color: #fff !important;
                    font-size: 18px;
                    text-decoration: none;
                }
            }
            .resetcode_button {
                background: #27cba4;
                color: #ffffff;
                border-radius: 15px;
                width: 100%;
                padding: 10px 15px;
                text-align: center;
                border: none;
                font-size: 21px;
                display: block;
                margin: 0 auto;
                transition: all 0.2s ease-in;
            }
        }
        .resetcode_content_inner_body_bottom {
            padding: 20px;
            background: #ffffff;
            border-radius: 15px;
            margin-top: 20px;
            text-align: center;
            .not_account_contnet {
                p {
                    margin-bottom: 0;
                    color: #7e807f;
                    font-size: 18px;
                    a {
                        // color: #fff !important;
                        margin-left: 5px;
                    }
                }
                .signup_link{
                    font-size: 16px;
                    font-weight: bold;
                    margin-left: 2px;
                    color: #27cba4 !important;
                    text-transform: uppercase;
                }
            }
        }
        .resetcode_content_form_getapp {
            margin-top: 50px;
            margin-bottom: 20px;
            .getapp_title {
                text-align: center;
                p {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
            .app-download-box {
                .col-6 {
                    padding: 5px;
                }
                .ios-app-image {
                    text-align: right;
                    img {
                        width: 70%;
                    }
                }
                .android-app-image {
                    text-align: left;
                    img {
                        width: 70%;
                    }
                }
            }
            
        }
        
        @media screen and (max-width: 575px) {
            .resetcode_content_inner_head {
                margin-bottom: 50px;
            }
            .resetcode_content_inner_body_inner .resetcode_heading {
                font-size: 24px;
            }
            .resetcode_content_inner_body_inner .form-control,
            .resetcode_content_inner_body_inner .forget_password {
                margin-bottom: 15px;
            }
            .resetcode_content_inner_body_inner .forget_password a,
            .resetcode_content_inner_body_inner .resetcode_button,
            .resetcode_content_inner_body_inner .not_account_contnet p {
                font-size: 16px;
            }
        }
    }
    
    .error_message span{
        color: red !important;
    }
    .cst_info_alert {
        position: fixed !important;
        top: 25px;
        right: 25px;
        animation: cst-info-alert 0.3s ease-in;
        width: 100%;
        max-width: 320px;
    }
    @keyframes cst-info-alert {
        from {
            transform: translateY(-150px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}