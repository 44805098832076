
.edit_profile_content_wrapper {
    overflow-y: auto;
    height: 100%;
    .editprofile_content{
        margin: auto;
    }
    .editprofile_heading{
        text-align: center;
        height: 50px;
        line-height: 2.2;
        font-size: 20px;
        color: #fff;
    }
    .update_profile .btn{
        color: #fff;
        background-color: #5fcc40 !important;
        border-color: #5fcc40 !important;
        width: 100%;
        margin-top: 10px;
        font-size: 18px;
    }
    .btn-primary:focus{
        box-shadow: none !important;
        outline: none;
    }
    .company_logo{
        height: 75px;
        text-align: center;
    }
    .user_details {
        width: 100%;
    }
    .user_details .form-control:focus{
        box-shadow: none !important;
    }
    .choose_option select{
        width: 100%;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        color: #495057;
        border-radius: 5px;
        padding: 8px;
        outline: none;
        margin-top: 10px;
    }
    .edit_profile_form_fields_wrapper {
        background: #fff;
        padding: 15px;
        border-radius: 4px;
    }
    .edit_profile_form_fields_wrapper .form-group {
        margin-bottom: 1rem !important;
    }
    .edit_profile_form_fields_wrapper .form-group,
    .edit_profile_form_fields_wrapper button {
        margin-top: 1rem;
    }
    .edit_profile_form_fields_wrapper .form-control,
    .edit_profile_form_fields_wrapper select {
        border-radius: 0;
        height: 44px;
        border-radius: 4px;
    }
    .edit_profile_form_fields_wrapper .form-control {
        border: 1px solid lightgray;
    }
    .edit_profile_form_fields_wrapper select {
        margin-top: 0;
    }
    .default_amount{
        position: relative !important;
        display: inherit !important;
    }
    .default_amount  input{
        line-height: 44px;
        padding: 0 10px 0 40px;
    }
    .default_amount::after{
        content: '$';
        font-family: Open Sans,sans-serif !important;
        width: 35px;
        height: 44px;
        line-height: 44px;
        position: absolute;
        left: 0;
        top: 0;
        border-right: 1px solid lightgray;
        text-align: center;
    }
    .allow_email_checkbox label {
        margin-bottom: 0;
        display: inline-block;
        vertical-align: middle;
    }
    .allow_email_checkbox input[type=checkbox] {
        position: static;
        display: inline-block;
        vertical-align: middle;
        margin: 0 0 0 10px;
        width: 1.4em;
        height: 1.1em;
    }
    .allow_email_checkbox {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-bottom: 5px;
    }
    .default_order_amount{
        margin-bottom: 0px;    
        font-size: 15px;
        font-weight: 400;
    }
    @media screen and (max-width: 575px) {
        .allow_email_checkbox {
            display: -webkit-box;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 5px;
        }
    }
    /* Change Password CSS */
    
    .changepassword_content{
        margin-top: 0px;
    }
    .changepassword_heading{
        text-align: center;
        height: 50px;
        line-height: 2.2;
        font-size: 20px;
        color: #fff;
        margin-top: 15px;
    }
    .change_password_field{
        margin-top: 0px;
        margin-bottom: 0px !important;
    }
    .change_confirm_password_field{
        margin-top: 10px;
        margin-bottom: 0px !important;
    }
    .errorMsg{
        color: red;
    }
    .change_password_submit .btn-primary{
        background-color: #5fcc40 !important;
        border-color: #5fcc40 !important;
        width: 100%;
        margin-top: 20px !important;
    }
    
                    /* Social Media CSS */
    
    .px-15px {
        padding-left: 15px;
        padding-right: 15px;
    }
    .edit_profile_form_fields_wrapper .row.mt-mb-1 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .edit_profile_form_fields_wrapper .form-group.mt-mb-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .profile-info-tooltip {
        border-radius: 100px;
        margin-top: 0px;
        width: 20px;
        height: 20px;
        padding: 0;
        margin-left: 20px;
        display: block;
        // border: 1px solid #999;
        background: transparent !important;
        color: #999;
        border-color: #999 !important;
        padding-top: 3px;
        cursor: pointer;
        position: relative;
    }
    .profile-info-tooltip i {
        font-size: 14px;
    }
    .profile-info-tooltip .tooltip-alias-text {
        visibility: hidden;
        width: 160px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
      
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
        top: 30px;
        left: 50%;
        margin-left: -80px;
    }
    .profile-info-tooltip:hover .tooltip-alias-text {
        visibility: visible;
        font-size: 14px;
    }
    .alias-input-field input {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
    .user-profile-upload-image {
        text-align: center;
    }
    // .user-profile-upload-image img {
    //     width: 140px;
    //     height: 140px;
    //     object-fit: contain;
    //     -o-object-fit: contain;
    //     border-radius: 100px;
    //     border: 1px solid #8bc53f;
    // }
    .user-profile-upload-image .imgShow {
        width: 140px;
        height: 140px;
        object-fit: contain;
        -o-object-fit: contain;
        border-radius: 100px;
        border: 1px solid #8bc53f;
    }
    
    .upload-profile-photo-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: block;
    }
      
      .upload-profile-photo-btn-wrapper-btn {
        border: transparent;
        color: #8DC73F;
        background-color: transparent;
        padding: 8px 8px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
      }
      .upload-profile-photo-btn-wrapper-btn input::before {
        cursor: pointer;
      }
      .upload-profile-photo-btn-wrapper input[type=file] {
        font-size: 30px;
        position: absolute;
        left: 43%;
        top: 35%;
        opacity: 0;
        width: 105px;
        height: 35px;
        margin: auto;
        cursor: pointer;
        z-index: 999;
      }
      .file-wrapper input {
        margin: 10px;
        width: 120px;
        height: 30px;
        cursor: pointer;
        font-size: 1px;
        filter: alpha(opacity=1);
        -moz-opacity: 0;
        opacity: 0;
        position: absolute;
        left: 26%;
        top: -4px;
      }
      .file-wrapper {
          position: relative;
      }
      .file-wrapper .button {
        margin: 10px;
        width: 120px;
        height: 26px;
        background: transparent;
        text-align: center;
        cursor: pointer;
        display: inline-block;
        color: #8DC73F;
        font-weight: bold;
      }
      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 22px;
      }
      
      .switch input { 
        opacity: 0;
        width: 0;
        height: 0;
      }
      
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0px;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
      
      .slider:before {
        position: absolute;
        content: "";
        height: 18px;
        width: 18px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        transition: .4s;
        top: 2px;
      }
      
      input:checked + .slider {
        background-color: #74d040;
      }
      
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
      
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
      
      /* Rounded sliders */
      .slider.round {
        border-radius: 100px;
      }
    
      .slider.round:focus{
        outline: none;
      }
      
      .slider.round:before {
        border-radius: 50%;
      }
}

                /* Edit Profile CSS */

