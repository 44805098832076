
.message_content{
    margin: auto;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
.offering_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #1c1c1c;
}
.message_box{
    margin-left: -10px;
    margin-right: -15px;
    overflow: scroll;
    height: calc(100% - 0px);
}
.active_message_box{
    overflow-x: hidden;
    padding: 15px 30px;
    overflow-y: auto;
    height: 100%;
}