.panel-header-title>.card-heading {
    color: #fff;
    background-color: #8DC73F;
    border-color: #8DC73F;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    padding: 10px;  
}
.panel-header-title {
    border-color: #8DC73F;
}
.attestation-content{
    margin: 20px 0px !important;
}
.attestation-body-content{
    text-align: justify;
    max-width: calc(100% - 50px);
    margin: 0 auto;
}
::-webkit-scrollbar {
    width: 5px;
}
.attestation-content .card-body {
    padding: 1.25rem 0;
}