.create_broker_box_inner_content_form{
    fieldset {
        padding: 10px;
        border: 1px solid #689F38;
    }
    legend {
        width: auto;
        padding: 0 10px;
        font-size: 0.75rem;
        color: #689F38;
    }
    // background: #fff;
    margin-top: 10px;
    padding-bottom: 15px;
    margin: auto;
    // height: calc(100% - 73px);
    // overflow-y:  auto;
    .create_brokerage_body {
        margin: 10px;
        border: 1px solid #ddd;
        padding-bottom: 20px;
        label {
            font-size: 14px;
        }
        .MuiInput-root {
            border-radius: 4px;
        }
        .MuiInput-underline:after {
            border-bottom: 0px solid #689F38;
        }
        .form-control {
            border: 1px solid rgba(0,0,0,0.32);
        }
        select {
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.32);
        }
        .css-yk16xz-control {
            border-color: rgba(0,0,0,0.32);
        }
        .p-multiselect {
            border: 1px solid rgba(0,0,0,0.32) !important;
            border-radius: 4px !important;
        }
    }
    .title_heading_form {
        font-size: 18px;
        font-weight: bold;
        margin-top: 3rem;
        // margin-bottom: 20px;
        width: max-content;
        border-bottom: 3px solid #8dc73f;
        color: #8dc73f;
    }
    .calendar {
        .p-calendar, .p-component {
            width: 100%;
        }
        .p-datepicker table td {
            padding: 0;
        }
        .p-datepicker table th {
            padding: 0;
        }
    }
    .showhidepassword-box {
        position: relative;
        input {
            padding-right: 35px;
        }
        .showhidepasswordbtn {
            position: absolute;
            bottom: 9px;
            right: 10px;
            cursor: pointer;
            background: #fff;
            padding-left: 5px;
            .MuiSvgIcon-root {
                font-size: 1.2rem;
            }
        }
    }
    .checkbox-choice {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .checkbox-choice input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: #eee;
      }
      
      /* On mouse-over, add a grey background color */
      .checkbox-choice:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .checkbox-choice input:checked ~ .checkmark {
        background-color: #2196F3;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .checkbox-choice input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .checkbox-choice .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    .btn-submit-brokerage, .btn-submit-brokerage:hover{
        background-color: #5fcc40 !important;
        border-color: #5fcc40 !important;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px !important;
        color: #ffffff;
    }
    .btn-file-upload {
        background-color: #5fcc40;
        border-color: #5fcc40;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 30px !important;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            color: #ffffff;
        }
        input {
            position: absolute;
            font-size: 50px;
            opacity: 0;
            right: 0;
            top: 0;
            cursor: pointer;
        }
    }
    .errorvalidation {
        color: red;
        font-size: 14px;
    }
    .p-multiselect .p-multiselect-label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .pointerCursor {
        cursor: pointer;
    }
}