
.order_detail_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    margin: auto;
    margin-bottom: 10px;
    text-transform: capitalize;
    position: relative;
}
.company_order_tag{
    font-weight: bold !important;
    font-size: 27px !important;
    margin-bottom: 0px !important;
    color: #678899 !important;
    text-align: center;
}
.create_broker_box_inner_content{
    // background: #fff;
    margin-top: 10px;
    padding-bottom: 15px;
    margin: auto;
    // height: calc(100% - 73px);
    // overflow-y:  auto;
    .create_brokerage_body {
        margin: 10px;
        border: 1px solid #ddd;
        padding-bottom: 20px;
    }
    .title_heading_form {
        font-size: 18px;
        font-weight: bold;
        margin-top: 3rem;
        // margin-bottom: 20px;
        width: max-content;
        border-bottom: 3px solid #8dc73f;
        color: #8dc73f;
    }
    .calendar {
        .p-calendar, .p-component {
            width: 100%;
        }
    }
    .checkbox-choice {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      /* Hide the browser's default checkbox */
      .checkbox-choice input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      
      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 22px;
        width: 22px;
        background-color: #eee;
      }
      
      /* On mouse-over, add a grey background color */
      .checkbox-choice:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      /* When the checkbox is checked, add a blue background */
      .checkbox-choice input:checked ~ .checkmark {
        background-color: #2196F3;
      }
      
      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      /* Show the checkmark when checked */
      .checkbox-choice input:checked ~ .checkmark:after {
        display: block;
      }
      
      /* Style the checkmark/indicator */
      .checkbox-choice .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    .btn-submit-brokerage, .btn-submit-brokerage:hover{
        background-color: #5fcc40 !important;
        border-color: #5fcc40 !important;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px !important;
        color: #ffffff;
    }
    .btn-file-upload {
        background-color: #5fcc40;
        border-color: #5fcc40;
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 30px !important;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:hover {
            color: #ffffff;
        }
        input {
            position: absolute;
            font-size: 50px;
            opacity: 0;
            right: 0;
            top: 0;
            cursor: pointer;
        }
    }
    .errorvalidation {
        color: red;
        font-size: 14px;
    }
    .p-multiselect .p-multiselect-label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
.oeder_user_images{
    width: 45%;
}
.modify_order_button{
    margin-bottom: 15px;
    text-align: center;
}
.modify_order_button .btn{
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
}
.modify_order_button a{
    color: #fff !important;
    text-decoration: none !important;
}
.order_details_btn{
    margin-top: 20px;
}
.share_it{
    margin-top: 10px;
}


@media (max-width: 767px){
    .modify_order_button .btn{
        padding: 0 0;
    }
    .order_detail_heading{
        width: 100%
    }
    .add-on{
        width: 100% !important;
    }
}

.pt-15px {
    padding-top: 15px;
}
@media screen and (max-width: 1199px) {
    .modify_order_button_wrapper {
        margin-top: 15px;
    }
}
@media screen and (max-width: 479px) {
    // .order_details_box_inner_content > .row > div:nth-child(1),
    // .order_details_box_inner_content > .row > div:nth-child(2) {
    //     -ms-flex: 0 0 100%;
    //     flex: 0 0 100%;
    //     max-width: 100%;
    // }
}
.create_brokerage_form {
    height: 100%;
    background: #ffffff;
    padding-top: 10px;
}
.delete_order_confirm_text{
    color: rgba(8,57,82,1);
    text-align: center;
    font-size: 19px;
}
.cancel_order_content{
    color: rgba(74,74,74,1);
    font-size: 18px;
}
.prospectus_icon_text{
    color: #44C6D2;
    font-weight: 500;
    cursor: pointer;
    float: right;
    display: flex;
}
.account_open_order_date{
    float: right;
    margin-bottom: 5px !important;
    font-weight: 600 !important;
    font-size: 17px;
    color: #678889 !important;
}
@media screen and (max-width: 575px) {
    .company_order_tag {
        text-align: left;
    }
    .create_broker_box_inner_content {
        // height: calc(100% - 88px);
    }
}

.account_open_order_date_two {
    color: #336e8d !important;
    font-weight: normal !important;
    font-size: 16px;
}
.input.has-error {
    border-color: tomato;
  }
.tag-item {
    background-color: #d4d5d6;
    display: inline-block;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
  }
  
  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }