
::-webkit-scrollbar {
    width: 5px;
}
.offering-list_page {
    background: #202020;
}
.offering-list_page .offering-list_header {
    position: fixed;
    width: 100%;
    background: #fafafa;
    z-index: 999;
}

.offering-list_page .offering-list_content {
    padding-top: 120px;
}
.offering-list-datatable .p-datatable-thead th .pi {
    display: block;
    width: 100%;
    font-size: 14px;
}
.offering-list-datatable .p-datatable-tbody tr td {
    font-family: Open Sans,sans-serif !important;
    /* color: #0A5074; */
}
.offering-list-datatable .p-datatable-tbody .p-datatable-emptymessage {
    text-align: center;
}
.offering-list-datatable .p-datatable-thead > tr > th {
    background-color: #464646 !important;
    color: #c8c8c8 !important;
    border: 1px solid #636363 !important;
}
.offering-list-datatable .p-datatable-scrollable-view .p-datatable-scrollable-body{
    /* max-height: calc(100vh - 260px); */
}
.offering-list-datatable .p-datatable-scrollable-header .p-datatable-scrollable-header-box {
    /* margin-right: 5px !important; */
    /* margin-left: 0px; */
}
.offering-list-datatable .p-datatable .p-datatable-scrollable-header {
    background-color: #464646 !important;
}
.offering-list-datatable .p-datatable {
    border: 1px solid #464646;
}
.offering-list-datatable .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #202020;
}
.offering-list-datatable .p-datatable .p-datatable-tbody > tr {
    background: #151515;
}
.offering-list-datatable .p-datatable-tbody > tr > td {
    color: #c8c8c8 !important;
    border: 1px solid #464646 !important;
}
.searchbox-offering-list input, .searchbox-offering-list input:focus{
    background: transparent;
    color: #c8c8c8 !important;
    border-color: #464646;
}
@media (max-width: 411px) {
    .offering-list-datatable .p-datatable-scrollable-view .p-datatable-scrollable-body{
        height: auto;
    }
}
@media (min-width: 767px) {
    .offering-list-datatable .p-datatable-scrollable-view .p-datatable-scrollable-body {
        /* max-height: calc(100vh - 260px); */
    }
}
@media (max-width: 640px) {
    .offering-list-datatable .p-datatable-scrollable-view .p-datatable-scrollable-body {
        /* max-height: calc(100vh - 240px); */
    }
    .p-datatable table {
        table-layout: auto !important;
    }
    .offering-list_content .offering-list-datatable .p-datatable-scrollable-body-table .tableCols {
        color: #c8c8c8;
    }
    .offering-list_content .offering-list-datatable .p-datatable-scrollable-body-table .tableCols .p-column-title {
        color: #c8c8c8;
    }
}
@media only screen and (max-width: 766px) and (min-width: 641px) {
    .offering-list-datatable .p-datatable-scrollable-view .p-datatable-scrollable-body {
        /* max-height: calc(100vh - 300px); */
    }
}