
.modify_order_content{
    margin: auto;
    padding-top: 1rem;
    width: 100%;
    background-color: #fff;
}
.modify_order_heading{
    background: linear-gradient(to left top, rgba(76,199,63,1), rgba(183,225,67,1));
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
    margin-bottom:15px;
}
.success-modifyorder-icon {
    text-align: center;
}
.success-modifyorder-icon img {
    width: 150px;
    margin: 16px 0px 32px 0px;
}
.modify_order_heading img{
    width: 100%;
    max-width: 30px;
    float: left;
    margin-left: 15px;
    margin-top: 10px;
    cursor: pointer;
}
.modify_order_inner_content{
    margin-top: 15px;
    margin: auto;
}
.box_content{
    margin-top: 10px;
}

.order_user_images{
    width: 45%;
}
.company_modifyorder_tag{
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 0px;
}
.company_modifyorder_account{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 17px;
}
.company_modifyorder_tag input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: bold;
    font-size: 17px;
}
.company_modifyorder_account input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
}
.company_modifyorder_name input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
}
.account_modifyorder_type input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-size: 17px;
}
.account_open_modifyorder_date input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
}
.price_level_modifyorder_type input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-size: 17px;
}
.amount_modifyorder input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: 500;
    font-size: 17px;
}
.description_heading{
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 3px !important;
}
.description_content{
    margin-right: 0px;
}
.description_content  input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: 400;
    font-size: 17px;
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for WebKit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
        hyphens: auto;
}
.item_link{
    margin-bottom: 0px !important;
    font-weight: bold;
    font-size: 17px;
}
.order_email{
    margin-bottom: 0px !important;
    font-weight: bold;
    font-size: 17px;
    float: right;
}
.order_status{
    font-weight: bold;
    font-size: 17px;
}
.investment_status{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 15px;
}
.investment_amount{
    float: left;
}
.investment_amount input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    padding-left: 14px;
}
input[type=text]:focus{
    outline: none;
}
.share_status{
    margin-bottom: 0px !important;
    font-weight: 500;
    font-size: 17px;
}
.share_amount  input[type=text]{
    border: 2px solid #ced4da;
    padding: 3px;
    border-radius: 4px;
    width: 100%;
    font-weight: bold;
    font-size: 17px;
}
.investment_content{
    margin-bottom: 10px;
    margin-top: 10px;
}
.placeorder_btn{
    margin: auto;
}
.placeorder_btn .btn{
    color: #fff;
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
    width: 100%;
    margin-top: 10px;
    font-size: 18px;
}
.placeorder_btn a{
    color: #fff !important;
    text-decoration: none !important;
}

.mt-25px {
    margin-top: 15px;
}
.pt-10px {
    padding-top: 10px; 
}
.bdr-top-1px-solid-lightgray {
    border-top: 1px solid lightgray;
}

.green_btn,
.red_btn,
.turquoise_btn {
    min-width: 150px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border: none !important;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
    color: #fff;
    background-color: #44c6d2;
}
.green_btn_submit {
    width: 100%;
    font-size: 16px;
    /* border-radius: 4px;
    font-weight: 600;
    cursor: pointer;
    border: none !important;
    text-align: center;
    padding: 10px;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
    color: #fff; */
}
.green_btn {
    background-image: linear-gradient(to right,rgba(160, 219, 65, 1) 0%,rgba(109, 206, 64, 1) 100%);
}
/* .green_btn_submit {
    background-image: linear-gradient(to right,rgba(160, 219, 65, 1) 0%,rgba(109, 206, 64, 1) 100%);
} */
.green_btn.disabled, .green_btn:disabled {
    opacity: .65;
}
.green_btn_submit.disabled, .green_btn_submit:disabled {
    opacity: .65;
}
.red_btn {
    background-color: #bd2130; 
}
@media screen and (max-width: 991px) {
    .modify_btn {
        margin-top: 15px;
        margin-bottom: 15px;
    }
}

@media (max-width: 767px){
    .modify_order_inner_content{
        width: 100%;
        margin: auto;
    }
    .order_email{
        float: left;
        margin-left: 8px;
    }
    .modify_btn .btn{
        padding: 0 0;
    }
    .order_user_images{
        width: 100%
    }
    .modify_order_heading img{
        width: 5%;
        float: left;
        margin-left: 6px;
        margin-top: 15px;
        cursor: pointer;
    }
}
@media screen and (max-width: 479px) {
    .place_order_inner_content > .row > div:nth-child(1),
    .place_order_inner_content > .row > div:nth-child(2) {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.styles_modal__gNwvD{
    max-width: fit-content;
}
.place_order_content .modal_css{
    height: 85vh;
    overflow: auto;
    text-align: justify;
    max-width: calc(100vh);
    margin: 0 auto;
    width: 100vh;
}

.order-modify-success-message {
    line-height: 1.6;
    max-width: 100%;
    padding: 0% 10%;
}
.order-modify-success-message b{
    font-size: 18px;
}
