
.condition_content{
    margin: auto;
    height: 100%;
}
.condition_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}
.condition_inner_content {
    overflow-y: auto;
    height: calc(100% - 0px);
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
    padding-bottom: 0px;
}
.condition_inner_content h4 {
    color: #333333;
    margin-top: 35px;
}
.condition_inner_content ol {
    padding-left: 15px;
}
.c26{
    margin-bottom: 10px;
}
.c7{
    margin-bottom: 5px;
}


@media (max-width: 767px){
    .condition_content{
        margin-top: 0px;
        padding-bottom: 10px;
    }
    .condition_inner_content{
        overflow-x: hidden;
    }
}
