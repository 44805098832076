.broker_dealer_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
}
.display-text-style {
    padding: 16px;
    border: 1px solid #ddd;
    height: 200px;
    overflow: hidden;
    overflow-y: scroll;
}
.broker_dealer_content{
    margin: auto;
    background-color: #fff;
    padding: 15px;
    height: 100%;
}
.broker_dealer_content a:hover{
    text-decoration: none;
}
.trade_station_box{
    margin-top: 10px;
}
.left_trade_station_box_inner{
    border: 1px solid lightgray;
    margin-top: 10px;
    margin-bottom: 10px;
    background:#d3d3d352;
    padding-bottom: 5px;
}
.name_company{
    color: black;
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 0px !important;
    line-height: 2.8;

}
.logo_company{
    text-align: center;
    line-height: 3;
}
.logo_company img{
    width: 65%;
    background: #fff;
    padding-top: 10px;
    padding-bottom: 10px;
}
.company_contect{
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
}
.create_company_account{
    padding: 5px;
    font-size: 20px;
    font-weight: 500;
}
.connect_disconnect{
    margin-bottom: 10px;
    text-align: center
}
.offering_button{
    margin-bottom: 15px;
    text-align: center;
}
.offering_button a{
    color: #fff !important;
}
.offering_button .btn-primary{
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
    margin-top: 0px;
}
.btn-danger{
    width: 100%
}
.offering_button a:hover{
    text-decoration: none;
}
.connect_disconnect button:focus{
    outline: none;
}
.btn-danger:focus{
    box-shadow: none !important;
}

@media (max-width: 767px){
    .broker_dealer_content{
        margin-top: 0px;
    }
    .right_trade_station_box_inner{
        width: 100%;
        padding: 5px;
    }
    .left_trade_station_box_inner{
        padding: 5px;
        height: 75px;
    }
    .name_company{
        font-size: 14px;
    }
    .logo_company{
        text-align: left;
        line-height: 0;
    }
    .logo_company img{
        width: 33%;
    }
    .create_company_account{
        padding: 0px;
        font-size: 15px;
    }
    .company_contect{
        padding: 0px;
        font-size: 15px;
    }
    .trade_station_box{
        margin-left: 0px;
    }
    .brokerage_content{
        margin-top: 0px;
    }
}

.brokerage_content{
    margin: auto;
}
.brokerage_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
    padding-left: 20px;
}
.brokerage_content_box_inner{
    border-radius: 4px;
    background: #fff;
}
.broker_dealer_name{
    margin-top: -4px;
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    border-bottom: 1px solid lightgray;
    line-height: 2;
    color: #333333;
}
.ipo_buying_power{
    text-align: center;
    font-weight: 500;
}
.accounts{
    font-weight: 500;
    text-align: center
}
.account_id{
    font-weight: 400;
    margin-left: 3px;
}
.account_type_firms{
    font-weight: 400;
    float: left;
    color: #336e8d;
}
.buying_power{
    font-weight: 400;
    margin-left: 3px;
}
.accounts_content_box_inner{
    border: 1px solid lightgray;
    text-align: center;
    margin-top: 10px;
    line-height: 2;
    height: 46px;
    background: #fff;
    padding: 0 15px;
}
.dealer_name{
    font-size: 18px;
    text-align: center;
    font-weight: 600;
    margin-top: 5px;
}
.link_dealer_account{
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
}
.link_dealer_account .btn-primary{
    background-color: #5fcc40 !important;
    border-color: #5fcc40 !important;
    width: 100%;
    margin-top: 0px;
}
.dealer_accounts_name{
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
}
.order_text{
    margin-top: 10px;
    font-size: 18px;
    font-weight: 500;
    color: #245776;
}

.brokerage_content_box_inner_box p {
    color: #678899;
}
.brokerage_content_box_inner_box > .row > div:first-child p {
    text-align: left;
    font-size: 15px;
}
.brokerage_content_box_inner_box > .row > div:last-child {
    text-align: right;
}

@media screen and (min-width: 376px) and (max-width: 575px) {
    .broker_dealer_connect_button_wrapper > div {
        display: inline-block;
        vertical-align: top;
        width: calc((100% - 15px) / 2);
    }
    .broker_dealer_connect_button_wrapper > div:nth-child(2) {
        margin-left: 15px;
    }
}

@media screen and (min-width: 462px) and (max-width: 767px) {
    .logo_company img{
        width: 20%;
    }
}

.active_accounts_list_content_box {
    border-radius: 4px;
    background-color: #fff;
}

.styles_modal__gNwvD{
    max-width: 515px;
}
.brokerage_text{
    color: #234c62;
    font-weight: 500;
    text-align: center;
    margin: 10px;
}