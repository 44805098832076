body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #root {
  height: 100%;
  font-family: Open Sans,sans-serif !important;
}
.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  z-index: 9999;
  opacity: 0.7;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body .p-treetable .p-treetable-thead > tr > th {
  border: 5px solid #ffffff !important;
  background-color: #f4f4f4 !important;
  padding: 0.857em 0.857em !important;
}
body .p-treetable .p-treetable-tbody > tr > td {
  border: 5px solid #ffffff !important;
  padding: 0.857em 0.857em !important;
  word-wrap: break-word !important;
}
body .p-treetable .p-treetable-tbody > tr:focus > td:first-child {
  box-shadow: none !important;
}
body .p-treetable .p-treetable-tbody > tr:focus > td {
  box-shadow: none !important;
}

body .p-treetable .p-treetable-tbody > tr:hover > td {
  background-color: #edeeef !important;
  color: #000 !important;
}
body a:hover {
  color: #689f38 !important;
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
body .p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #848484 !important;
}
body .p-treetable .p-sortable-column.p-highlight {
  color: #333333 !important;
}

button:focus {
  outline: 0px auto -webkit-focus-ring-color !important;
}
html, body, #root {
  height: 100%;
}
.Loader, .Loader__content {
  height: 100%;
}
.p-datatable .p-datatable-thead > tr > th, .p-datatable .p-datatable-tbody > tr > td {
  font-family: Open Sans,sans-serif !important;;
}
.styleclassyellow {
  background-color:  #cccc15 !important;
  color: #fff !important;
}
.styleclassgreen {
  background-color: #167a14 !important;
  color: #fff !important;
}
.styleclassred {
  background-color: #e01613 !important;
  color: #fff !important;
}
.internet-error {
  /* height: 60px; */
  background: #ff8100;
  /* margin-top: 0;
  font-size: 20px; */
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99;
  text-align: center;
}

.internet-error p {
  font-size: 25px;
  line-height: 60px;
  color: #fff;
  margin: 0;
}
.ipo_interested_btn{
  background: #5fcc40 !important;
  color: #fff !important;
  border-color: #5fcc40 !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}
.turquoise_interested_btn{
  background: #44c6d2 !important;
  color: #fff !important;
  border-color: #44c6d2 !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}
.etf_interested_btn{
  background: #009167 !important;
  color: #fff !important;
  border-color: #009167 !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}
.uit_interested_btn{
  background: #0493AD !important;
  color: #fff !important;
  border-color: #0493AD !important;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}

.etf_btn {
  min-width: 150px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  border: none !important;
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
  color: #fff;
  background-color: #009167;
}
.uit_btn {
  min-width: 150px;
  font-size: 16px;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  border: none !important;
  text-align: center;
  padding: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
  color: #fff;
  background-color: #0493AD;
}
.green_text {
  color: #8DC73F !important;
}
.turquoise_text {
  color: #44c6d2 !important;
}
.etf_text {
  color: #009167 !important;
}
.uit_text {
  color: #0493AD !important;
}
.etf_interested_btn{
    background: #009167 !important;
    color: #fff !important;
    border-color: #009167 !important; 
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}
.uit_interested_btn{
    background: #0493AD !important;
    color: #fff !important;
    border-color: #0493AD !important;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.35);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
    width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(117, 116, 116, 0.4);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}