.clickipo_ipo{
    width: 100%;
    text-align: center;
    height: 75px;
    margin: 0;
    line-height: 4.5;
}
.symbol_header{
    position: fixed;
    width: 100%;
    background: #fff;
}
.symbol_content{
    padding-top: 85px;
}
.no_symbol{
    text-align: center;
    font-size: 20px;
    background: #fff;
}

.order_status{
    font-weight: 500 !important;
    font-size: 16px;
    color: #336e8d !important;
    margin-bottom: 5px;
    display: block;
}

.order_status_text{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 5px;
    text-align: justify;
    word-break: break-all;
    color: #678899;
}