



.allow_notification_checkbox label {
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    font-size: 16px;
}
.allow_notification_checkbox input[type=checkbox] {
    position: static;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
    width: 1.4em;
    height: 1.1em;
}
.allow_notification_checkbox {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
@media screen and (max-width: 575px) {
    .allow_notification_checkbox {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
}
.login_content_inner_body_bottom {
    padding: 20px;
    background: #ffffff;
    border-radius: 15px;
    margin-top: 20px;
    text-align: center;
    .not_account_contnet {
        p {
            margin-bottom: 0;
            color: #7e807f;
            font-size: 18px;
            a {
                // color: #fff !important;
                margin-left: 5px;
            }
        }
        .signup_link{
            font-size: 16px;
            font-weight: bold;
            margin-left: 2px;
            color: #27cba4 !important;
            text-transform: uppercase;
        }
    }
}
.signup-page {
    height: 100%;
    background: linear-gradient(180deg, #edf1f4, #ffffff);
    @media screen and (min-width: 300px) and (max-width: 575px) {
        .signup-content {
            padding-left: 10px;
            padding-right: 10px;
            .signup-company-logo-dsp{
                text-align: left;
                img {
                    width: 150px;
                    margin-bottom: 10px;
                    margin-top: 15px;
                }
            }
            .signup-company-logo {
                img {
                    width: 70%;
                    margin-bottom: 10px;
                    margin-top: 80px;
                }
            }
            .signup-platform-mockup {
                img {
                    display: none;
                }
            }
            .signup-platform-mockup-dsp {
                text-align: center;
                .signup-platform-mockup-dsp-welcome-text{
                    font-size: 26px;
                    margin-bottom: 45px;
                }
                img {
                    width: 35%;
                    margin-bottom: 25px; 
                }
                .signup-platform-mockup-dsp-description{
                    font-size: 18px;
                    text-align: justify;
                    text-justify: inter-word;
                }
            }
            .signup_content_inner_body {
                padding: 20px;
                margin-top: 20px;
            }
        }
    }
    @media screen and (min-width: 576px) {
        .signup-content {
            padding-left: 80px;
            padding-right: 80px;
            .signup-company-logo {
                img {
                    width: 300px;
                    margin-bottom: 50px;
                    margin-top: 30px;
                }
            }
            .signup_content_inner_body {
                padding: 40px 40px 20px 40px;
                margin-top: 120px;
            }
        }
    }
    .signup-content {
        width: 100%;
        .signup-company-logo {
            text-align: center;
        }
        .signup-platform-mockup {
            text-align: center;
            img {
                width: 100%;
            }
        }
        .signup-platform-mockup-dsp {
            text-align: center;
            .signup-platform-mockup-dsp-welcome-text{
                font-size: 26px;
                margin-bottom: 45px;
            }
            img {
                width: 35%;
                margin-bottom: 25px; 
            }
            .signup-platform-mockup-dsp-description{
                font-size: 18px;
                text-align: justify;
                text-justify: inter-word;
            }
        }

        .signup-company-logo-dsp{
            text-align: left;
            img {
                width: 150px;
                margin-bottom: 10px;
                margin-top: 15px;
            }
        }
        .signup_content_inner_body {
            background: #ffffff;
            border-radius: 15px;
            .signup_content_inner_body_inner {
                max-width: 500px;
                margin: 0 auto;
                position: relative;
                .top-border {
                    margin: auto;
                    width: 70px;
                    height: 5px;
                    background: #8ec549;
                }
                .signup_heading {
                    font-size: 36px;
                    // text-transform: uppercase;
                    padding-bottom: 10px;
                    height: auto;
                    text-align: center;
                    margin-top: 5px;
                }
            }
           
            .signup_heading:after {
                content: '';
                display: block;
                height: 1px;
                background-color: #fff;
                max-width: 121px;
                margin: 0 auto;
            }
            .form_content_signup {
                margin-top: 0px;
                p:empty {
                    display: none;
                }
            }
            .password-case-sensitive {
                margin-bottom: 15px;
                margin-top: 15px;
                font-size: 16px;
                // margin-left: 20px;
            }
            .form-control {
                height: 60px;
                padding: 10px 20px;
                font-size: 18px;
                background: #f8fafb;
                color: #4a4a4a;
                border-color: #d7d7d7;
                border-radius: 15px;
            }
            .form-control::-webkit-input-placeholder { /* Edge */
                color: #bebdbd;
              }
              
            .form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
                color: #bebdbd;
            }
              
            .form-control::placeholder {
                color: #bebdbd;
            }
            .form_field_wrapper > div > div {
                position: relative;
                margin-bottom: 15px;
            }
            .form_field_wrapper > div > div:before {
                content: '';
                position: absolute;
                height: 60px;
                background-repeat: no-repeat;
                background-size: 25px;
                background-position: 15px center;
                display: block;
                top:0;
                left:0;
                width: 55px;
            }
            .forget_password { 
                text-align: center;
                margin-top: 20px;
                a {
                    // color: #fff !important;
                    font-size: 18px;
                    text-decoration: none;
                }
            }
            .signup_button {
                // background-image: linear-gradient(to right,#a0db41 0%,#6dce40 100%);
                // box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
                // max-width: 180px;
                background: #27cba4;
                color: #ffffff;
                border-radius: 15px;
                width: 100%;
                padding: 10px 15px;
                text-align: center;
                border: none;
                // text-transform: uppercase;
                font-size: 21px;
                display: block;
                margin: 0 auto;
                transition: all 0.2s ease-in;
            }
        }
        .signup_content_inner_body_bottom {
            padding: 20px;
            background: #ffffff;
            border-radius: 15px;
            margin-top: 20px;
            text-align: center;
            .not_account_contnet {
                p {
                    margin-bottom: 0;
                    color: #7e807f;
                    font-size: 18px;
                    a {
                        // color: #fff !important;
                        margin-left: 5px;
                    }
                }
                .signup_link{
                    font-size: 18px;
                    margin-left: 2px;
                    color: #27cba4 !important;
                    text-transform: uppercase;
                }
            }
        }
        .signup_content_form_getapp {
            margin-top: 50px;
            margin-bottom: 20px;
            .getapp_title {
                text-align: center;
                p {
                    font-size: 18px;
                    margin-bottom: 5px;
                }
            }
            .app-download-box {
                .col-6 {
                    padding: 5px;
                }
                .ios-app-image {
                    text-align: right;
                    img {
                        width: 70%;
                    }
                }
                .android-app-image {
                    text-align: left;
                    img {
                        width: 70%;
                    }
                }
            }
            
        }
        
        @media screen and (max-width: 575px) {
            .signup_content_inner_head {
                margin-bottom: 50px;
            }
            .signup_content_inner_body_inner .user_icon {
                height: 70px;
                width: 70px;
                line-height: 70px;
                margin-bottom: -35px;
            }
            .signup_content_inner_body_inner .user_icon i {
                font-size: 2rem;
            }
            .signup_content_inner_body_inner .signup_heading {
                font-size: 24px;
            }
            .signup_content_inner_body_inner .form-control,
            .signup_content_inner_body_inner .forget_password {
                margin-bottom: 15px;
            }
            .signup_content_inner_body_inner .forget_password a,
            .signup_content_inner_body_inner .signup_button,
            .signup_content_inner_body_inner .not_account_contnet p {
                font-size: 16px;
            }
        }
        

        .form_content_signup{
            margin-top: 15px;
        }

        .form-control:focus{
            box-shadow: none !important;
        }
        .term_condition_text{
            text-align: center;
            margin-top: 5px;
            font-size: 14px;
            position: relative;
        }

        input[type=checkbox] {
            height: 15px;
            position: absolute;
            width: 40%;
            margin-top: 4px;
            z-index: 9999;
        }
        .agree_condition{
            margin-top: 5px;
            font-size: 14px;
            cursor: pointer;
        }

        .agree_condition:hover{
            cursor: pointer;
            color: blue;
            text-decoration: underline
        }

        .error_message span{
        color: red !important;
        }
        .term_condition_text span{
            color: red;
            position: absolute;
            top: 25px;
            left: 0;
        }
        
        .term_condition_model{
            height: 500px;
            overflow: auto;
            text-align: justify;
            max-width: calc(100% - 50px);
            margin: 0 auto;
        }
        
        .styles_closeButton__20ID4 {
            right: 18px;
        }
        .styles_closeButton__20ID4:hover {
            cursor: pointer;
        }
        .term_condition_text {
            margin: 0 0 35px;
            text-align: left;
        }
        .term_condition_text input[type=checkbox] {
            height: 15px;
            width: 15px;
            margin: 0 !important;
            padding: 0 !important;
            top: 50%;
            transform: translateY(-50%);
            z-index: 99;
        }
        .term_condition_text p {
            margin: 0;
            padding-left: 25px;
            color: #000;
            font-size: 16px;
            display: inline-block;
        }
        .term_condition_text p:hover {
            color: #000;
        }

        .cst_info_alert {
            position: fixed !important;
            top: 25px;
            right: 25px;
            animation: cst-info-alert 0.3s ease-in;
            width: 100%;
            max-width: 320px;
        }
        @keyframes cst-info-alert {
            from {
                transform: translateY(-150px);
                opacity: 0;
            }
            to {
                transform: translateY(0);
                opacity: 1;
            }
        }

        input:checked + .slider {
            background-color: #74d040;
        }
        
        input:focus + .slider {
            box-shadow: 0 0 1px #2196F3;
        }
        
        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
        @media screen and (max-width: 480px) {
            .term_condition_model {
                max-width: 100%;
                width: 100%;
                text-align: left;
            }
            .term_condition_model ol li h4 {
                font-size: 1rem;
            }
            .term_condition_model + button {
                right: 0;
                top: 0;
            }
        }
        @media screen and (max-width: 767px) {
            .term_condition_text input[type=checkbox] {
                position: absolute;
                top: 13px;
                z-index: 99;
            }
        }
    }
}
