
.socialmedia_content{
    margin: auto;
    background: #ffffff;
    height: 100%;
    padding: 1rem;
}
.socialmedia_heading{
    text-align: center;
    height: 50px;
    line-height: 2.2;
    font-size: 20px;
    color: #fff;
    margin-top: 15px;
}
.kep-login-facebook.metro{
    background: transparent;
    color: black;
    border: none;
    font-weight: 500;
    padding: 7px 0px 12px 0px;
    text-transform: none;
    text-align: left;
    font-size: 19px;
}
.social_media_image{
    margin-top: 6px;
    padding-bottom: 4px;
    text-align: center;
}
.social_media_image img{
    width: 40px;
}
.st_link_account{
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
}
.st_link_account a{
    cursor: pointer;
}

.st_link_account a:hover{
    text-decoration: none;
}
.social_links_wrapper button {
    padding: 0 !important;
    margin: 0 !important;
}
.social_Link_icon {
    max-width: 35px;
    margin-right: 15px;
}
.social_links_wrapper .social_links_content_box span,
.social_links_wrapper .social_links_content_box a,
.social_links_wrapper .social_links_content_box button {
    font-size: 16px;
    /* color: #000; */
    text-decoration: none !important;
}
.facebook_login_link.metro {
    background-color : transparent;
    border: none;
    width: 100%;
    text-align: left;
    padding-left: 65px !important;
    position: absolute;
    left: 0;
    top: 5px;
}
.facebook_login_link.metro:hover {
    cursor: pointer;
}
.social_links_content_box:not(:last-child) {
    border-bottom: 1px solid lightgray;
    padding-bottom: 15px;
}
@media screen and (max-width: 1199px) {
    .social_links_content_box:not(:last-child) {
        border-bottom: 1px solid lightgray;
        padding-bottom: 15px;
    }
    .social_links_content_box:not(:first-child) { 
        margin-top: 15px;
    }
}
@media (max-width: 767px){
    .socialmedia_content{
        margin-top: 0px;
    }
    .changepassword_content{
        margin-top: 0px;
    }
}
.add_stock_account{
    margin-top: 20px;
}

@media screen and (min-width: 1200px) {
    .xl-mt-15px {
        margin-top: 15px;
    }
}

.social_links_wrapper .social_links_content_box button.unlink_button {
    padding: 5px 15px !important;
    border-radius: 4px;
    background-color: transparent;
    color: #fff !important;
    cursor: pointer;
}
.not_link a{
    color: #fff !important;
}
.not_link a:hover{
    text-decoration: none;
}
.twitter_login{
    cursor: pointer;
    display: inline-block;
}

.facebookLogin {
    cursor: pointer;
    display: inline-block;
}

.socialMediaFooter {
    cursor: pointer;
    text-align: center;
    padding-top: 10px;
}
